
/* 

    ForecastModel / Algorithm to generate forecasted vaccination rates.
    
    Could not simplify Urban and Rural into one data set, as rural data is operated on slightly differently.

*/

import Logger from './Logger'
import Consts from './Consts'
import Utils from './Utils'

import {SolomonsExpectedData, TestData} from './SampleModelCountryData'

export default {

    USE_CACHE: true,

    _cache: {}, // Cache generated data in here

    MAX_SOLVER_ITERATIONS: 128, // how many months to sovle to reach 100

    logging: false,

    log:function() {
        if(this.logging) Logger.log.apply(null, arguments);
    },

    storeTest: function( values, expected, results_arr ) {
        for (const [key, value] of Object.entries(values)) { 
            
            let dif = (value-expected[key]);
            if(isNaN(dif)) {
                dif = "null";
            }else{
                dif = (dif == 0) ? 0 : Number((dif / value).toFixed(8));
            }
            
            results_arr.push({
                "variable" : key,
                "value": Number(value.toFixed(8)),
                "expected": expected[key],
                "dif": dif
            });
        }
            
    },
    
    printTests: function( results_arr ) {
        // Print them in a table
        if(this.logging) Logger.table( results_arr, ["variable", "value", "expected","dif"]);
    },

    test: function() {
        this.log("Model::test", TestData);

        /*         
            Working with Solomon Islands Model_V2.xlsm from "22 OCT LOWY SENT PAC VAC" Folder 
            Using the >18 data set to proof - the data set (Red side buffer in excel and blue line in the excel graph)
        */  
        var expected_data = {...SolomonsExpectedData}; // Hardcoded data from the Excel sheet.
        var country_model = {...TestData["model"]}; // copy
        
        // TODO: sort country model when they arrive in the store (if required)
        country_model["actual"]["months"].sort( (a, b) => new Date(a.dt) - new Date(b.dt) ); // Sort into newest date last

        var vaccinator_total = country_model["static"]["workforce"]["vaccinators"];
        var vaccine_acceptance = country_model["static"]["speed"]["acceptance"]; 

        let res = this.solve(   country_model, 
                                vaccinator_total, 
                                vaccine_acceptance, // acceptance variable
                                Consts.POP_18_PLUS, 
                                new Date("2021-11-1"), // Max date to loop through
                                expected_data );
        
        this.log("Test Result", res);
    },

    test2: function() {
        this.log("Model::test2");

        var expected_data = SolomonsExpectedData.ep_dose_p; // Hardcoded final month data from the Excel sheet.
        var country_model = {...TestData["model"]}; // copy

        var vaccinator_total = country_model["static"]["workforce"]["vaccinators"]; // TODO make this an input varialble
        var vaccine_acceptance = country_model["static"]["speed"]["acceptance"]; // TODO: need to use this to operate on the Static speed value somehow.
        
        let res = this.solve( country_model, 
            vaccinator_total, 
            vaccine_acceptance, // acceptance variable
            Consts.POP_18_PLUS, 
            new Date("2027-05-1"), // Max date to loop through
        );
        
        // !Problem is our model finsheds at the end very fast - it maintaintains the shape until the end. & the second does ends first.

        let res2 = res.map( (d) => [d.total_doses_1st_p[Consts.POP_18_PLUS], d.total_doses_2nd_p[Consts.POP_18_PLUS]] );
        this.log("Test2 Result", res2, expected_data);

        // iterate res, and plot against expected_data[n][0..1] in a table.

        // total_doses_1st_p total_doses_2nd_p

    },

    solve:function ( country_model, VACCINATORS_TOTAL, VACCINE_ACCEPTANCE, TARGET_POP_ID, max_date, test_expected_data ) {
        
        // Run model through from the next month/date from our last "Actual" data date
        // until the TARGET_POP_ID, both doses reaches 100 - or the max date.
        // test_expected_data is object of key/values that key variables needs to equal
        // If it exists then log the result too
        // debugger;
        this.log( "ForecastModel::solve", {country_model, VACCINATORS_TOTAL, VACCINE_ACCEPTANCE, TARGET_POP_ID, max_date, test_expected_data} );
        
        // Test if its in cache and if so generate.
        
        const CACHE_KEY = country_model.code + "-" + TARGET_POP_ID + "-" + VACCINATORS_TOTAL + "-" + VACCINE_ACCEPTANCE + "-" + Utils.dateToMYYYY(max_date);
        if(this.USE_CACHE) {

            if(this._cache[CACHE_KEY]) {
                Logger.log("Model:Already cached stream " + CACHE_KEY);
                return this._cache[CACHE_KEY]; // TODO: possibly return a clone of struture?
            }

        }

        if(country_model.actual.months.length < 3) {
            Logger.error("Country model requires 3 historical month data sets. " + country_model.actual.months.length + " found");
            return;
        }
        // Make sure we have date objects
        for(let i=0;i<country_model.actual.months.length;i++) {
            country_model.actual.months[i]["dt"] = Utils.mkDate( country_model.actual.months[i]["dt"] );
        }
        
        if(country_model.code == Consts.SOLOMONS) {
           // debugger;    
        }

        var three_months_ago_data = country_model.actual.months[0]; // for our historical az 1st dose refernces  
       
        var last_actual_month_data = country_model.actual.months[2];
        var last_actual_data_date = last_actual_month_data["dt"]; // The last historical actual data set, should always be at 3rd pos (index 2)
        
        // Use start_date
        // NOTE this would change every time we run a month, to point at the month generated, so it will need to have same keys
        
        // Hero vars. (external manipulation)
        var statics = country_model["static"];
        var max_vaccinators = statics["workforce"]["max_vaccinators"];

        var start_date = new Date(last_actual_data_date);
        start_date.setMonth(start_date.getMonth()+1);

        var end_date = max_date; // new Date(2021, 12, 1); // how far to plot ahead. 

        var prev_month_data = last_actual_month_data;
        var prev_month_date = new Date( prev_month_data["dt"] );  // Date of last actual data set
        
        this.log({start_date, last_actual_data_date, prev_month_date, end_date});

        var props = { // properties to a pass around
            TARGET_POP_ID
        };

        props.URB_RATE = statics["population"]["urb_rate"][TARGET_POP_ID]; // Static Inputs:D32
        props.vaccinators_urban = (VACCINATORS_TOTAL * props.URB_RATE); // (L29) Formula [taken from F29] =F28*'Static Inputs'!D32
        props.vaccinators_rural = VACCINATORS_TOTAL-props.vaccinators_urban; // (M30)

        this.log({VACCINATORS_TOTAL, max_vaccinators, URB_RATE:props.URB_RATE, vaccinators_urban:props.vaccinators_urban, vaccinators_rural:props.vaccinators_rural});
        
        // Note: To simplfy just using a single SLOPE (not urban or rural) as all provided data files had same values
        props.POP_ACEP_50 = (VACCINE_ACCEPTANCE * .5);  // (D58) % of urban population where acceptance is 50% initial value 
        props.SLOPE = 3.1699250014423126; //Math.log(1/9)/Math.log(.5); //statics["slope"]; // (D60) =  // Needs to be calculated to be more accurate

        /*
            D60 ==  
                = LN(1/9)/LN(D58/D59)
                = Math.log(1/9)/Math.log(D58/D59),
                = Math.log(1/9)/Math.log(.5) always 3.2 but let backend override it.
        */
         
        props.TOTAL_POP = statics["population"]["total"][TARGET_POP_ID];

        // Calculate population consts based on the urbanisation rate
        props.URBAN_POP = (props.TOTAL_POP * props.URB_RATE);
        props.RURAL_POP = props.TOTAL_POP - props.URBAN_POP;

        this.log( {TARGET_POP_ID, TOTAL_POP:props.TOTAL_POP, URBAN_POP:props.URBAN_POP, RURAL_POP:props.RURAL_POP} );
        
        props.vaccinator_speed_month = statics["speed"]["per_month"]; // $D$15
       
        this.log( {vaccinator_speed_month:props.vaccinator_speed_month, SLOPE:props.SLOPE, POP_ACEP_50:props.POP_ACEP_50} );

        // EXTERNAL FACTORS

        // These don't appear to change per doc so can use constants here
        props.VACC_ACCEP_2ND_DOSE = 1.0; // (M36) Vaccine acceptance 2nd dose
        props.RURAL_COMPLICATION_FACTOR = .5; // (M37) Rural vaccination complication factor
        
        props.AZ_12W = Utils.has(statics,["distrubution","az"]) ? Number(statics.distrubution.az) : .8; // (M39) AstraZeneca (12w) 
        props.OTHER_VAC_4W = Utils.has(statics,["distrubution","other"]) ? Number(statics.distrubution.other) : .2; // (M40) Other vaccines (4w)

        // Dev hack to fix CookIslands alt ratio as it has no AZ.
        if(country_model.code == Consts.COOK_ISLANDS)  {
            props.AZ_12W = 0;
            props.OTHER_VAC_4W = 1.0;
        }

        // End static stuff, loop here, update the prev month data etc,

        // Object of required properties that the solver needs
        this.log("Props",props);

        let cur_date = new Date(start_date);
        let i = 0;
        let looping = true;

        let solved_months = [];

        var at_100 = false;

        while(looping) {

            this.log("Loop", i, Utils.dateToSQL(cur_date), 
                        "Prev:" + Utils.dateToSQL( prev_month_data.dt ),
                        "-3 Mnths:" + Utils.dateToSQL( three_months_ago_data.dt ) );

            if(i >= this.MAX_SOLVER_ITERATIONS) { // Saftey test on 60 months
                this.log("Loop break", i);
                looping = false;
                break;
            }
            
            if( cur_date.getFullYear() == 2026 && cur_date.getMonth() == 2 && country_model.code == Consts.SOLOMONS) {
                // debugger;
            }

            let solved_data = this._solve( cur_date, prev_month_data, three_months_ago_data, props, i, test_expected_data );
            this.log(solved_data);

            this.log("solvent", i, Utils.dateToSQL(solved_data.dt), {
                urban_2nd_dose_p:solved_data.urban_2nd_dose_p[props.TARGET_POP_ID],
                urban_2nd_doses_total:solved_data.urban_2nd_doses_total[props.TARGET_POP_ID],
                rural_2nd_doses_total:solved_data.rural_2nd_doses_total[props.TARGET_POP_ID],
                total_2nd_dose_administered:solved_data.total_2nd_dose_administered[props.TARGET_POP_ID],
                urban_az_1st_dose:solved_data.urban_az_1st_dose[props.TARGET_POP_ID],
               /* urban_other_1st_dose:solved_data.urban_other_1st_dose[props.TARGET_POP_ID],*/
                cumulative_2nd_dose:solved_data.cumulative_2nd_dose[props.TARGET_POP_ID] // this is going out
            });

            solved_months.push( solved_data );

            this.log("RESULTS #" + props.TARGET_POP_ID + "[" + (i) + "]:" + Utils.dateToSQL(solved_data.dt), {dose_1st: solved_data.total_doses_1st_p[props.TARGET_POP_ID], dose_2nd: solved_data.total_doses_2nd_p[props.TARGET_POP_ID]} );

            if(solved_data.dt >= max_date) {
                this.log("Loop break for max_date", i, Utils.dateToSQL(solved_data.dt), Utils.dateToSQL(max_date) );
                looping = false;
                break;
            }

            // Test for the total_doses_2nd_p >= 100
            if( solved_data.total_doses_1st_p[props.TARGET_POP_ID] >= 100 &&
                solved_data.total_doses_2nd_p[props.TARGET_POP_ID] >= 100) {

                if(at_100) { 
                    this.log("Loop break for total_doses_2nd_p >= 100", i, Utils.dateToSQL(solved_data.dt), solved_data.total_doses_2nd_p[props.TARGET_POP_ID] );
                    looping = false;
                    break;
                }

                at_100 = true;
            
            }
            
            i++;

            if( i <= 2) {
                // We draw from the downloaded model from 3 months ago data
                three_months_ago_data = country_model.actual.months[i];
            }else{
                // Get it from the solved_months stack
                three_months_ago_data = solved_months[i-3];
            }
            
            prev_month_data = solved_months[solved_months.length-1]; //  solved_data;//

            // this.log({solved_data});

            // If we are at 100 2nd dose, as we need to modify the vaccinators, send em all to da bush.
            this.log("curr urban_2nd_dose_p", i, solved_data.urban_2nd_dose_p);
            
            if(solved_data.urban_2nd_dose_p[props.TARGET_POP_ID] >= 100) {
                // (M29) calc =IF(M104>1,0,M29)
                // (M30) calc =IF(AS104>1,AT28,(AT28-AT29))
                props.vaccinators_urban = 0;
                props.vaccinators_rural = VACCINATORS_TOTAL;
                this.log("At urban 2nd dose 100, sending vaccinators to the bush", {"urban":props.vaccinators_urban, "rural":props.vaccinators_rural}, cur_date);
            }

            cur_date = Utils.addMonths(cur_date, 1); // Next month.

        }

        if(this.USE_CACHE) {
            Logger.log("Model:Caching stream " + CACHE_KEY);
            this._cache[CACHE_KEY] = solved_months;
        }

        return solved_months;
    },


    // Real solve that runs calculations 
    _solve: function( dt, prev_month_data, three_months_ago_data, props, iteration, test_expected_data) {
        
        var self = this;
        
        this.log("_SOLVE:", iteration, Utils.dateToSQL(dt), Utils.dateToSQL(prev_month_data.dt), Utils.dateToSQL(three_months_ago_data.dt) );

        let expected = test_expected_data && test_expected_data[props.TARGET_POP_ID] ? test_expected_data[props.TARGET_POP_ID] : null;
        let tests_store = [];

        const get_acceptance_percentage = function(prev_dose) {
            
            // Urban % (M34) =IF(L103=0,1,1-1/(1+('Static Inputs'!$D$58/Model!L103)^'Static Inputs'!$D$60))
                // D58 = % of urban population where acceptance is 50% initial value
                // L103 = % Urban population w/ 1st dose
                // D58 =  STATIC % of urban population where acceptance is 50% initial value
                // D60 = Urban slope factor

            // Rural % (M35) =IF(L106=0,1,1-1/(1+('Static Inputs'!$D$62/Model!L106)^'Static Inputs'!$D$64))
            // D62
            // D64 = Rural slope factor

            if(prev_dose == 0) return 1;
            return  1 - 1 / ( 1 + Math.pow((props.POP_ACEP_50 / prev_dose), props.SLOPE));
        }

        // 1ST DOSE 
        let prev_urban_1st_dose = prev_month_data["urban_1st_dose_p"][props.TARGET_POP_ID]; // (L103)
        let prev_rural_1st_dose = prev_month_data["rural_1st_dose_p"][props.TARGET_POP_ID]; // (L106)
        let urban_acceptance_1st_dose = get_acceptance_percentage(prev_urban_1st_dose); // (M34)
        let rural_acceptance_1st_dose = get_acceptance_percentage(prev_rural_1st_dose); // (M35)

        // 2ND DOSE (These don't seem to be used?!)
        let prev_urban_2nd_dose = prev_month_data["urban_2nd_dose_p"][props.TARGET_POP_ID]; // L104
        let prev_rural_2nd_dose = prev_month_data["rural_2nd_dose_p"][props.TARGET_POP_ID]; // L107

        this.log({prev_urban_1st_dose, prev_rural_1st_dose, rural_acceptance_1st_dose});
        this.log({prev_urban_2nd_dose, VACC_ACCEP_2ND_DOSE:props.VACC_ACCEP_2ND_DOSE, prev_rural_2nd_dose});

        if(expected) this.storeTest( {urban_acceptance_1st_dose, rural_acceptance_1st_dose}, expected, tests_store );
        
        const get_first_dose_urban = function(prev_dose, dose_factor) {
            /*  
                =IF(L103>1,0,M29*'Static Inputs'!$D$15*M34*M39)
                L103= (prev_urban_1st_dose)
                M29= Vaccinators Urban (vaccinators_urban)
                $D$15= Speed of vaccination per month per healthcare worker - (vaccinator_speed_month)
                M34= Urban Vaccine acceptance 1st dose (urban_acceptance_first_dose)
                M39= "AstraZeneca (12w)" - (AZ_12W)
            */

            /* 
                =IF(L103>1,0,M29*'Static Inputs'!$D$15*M34*M40)
                As above +
                M40 = OTHER_VAC_4W
            */
            
            self.log("get_first_dose_urban", {prev_dose, dose_factor, urban_acceptance_1st_dose, vaccinators_urban:props.vaccinators_urban, vaccinator_speed_month:props.vaccinator_speed_month});

            if(prev_dose > 1) return 0;
            return props.vaccinators_urban * props.vaccinator_speed_month * urban_acceptance_1st_dose * dose_factor;
        }

        let urban_az_1st_dose = get_first_dose_urban(    prev_urban_1st_dose/100,   props.AZ_12W ); // (M50) AZ (12w) 1st dose
        let urban_other_1st_dose = get_first_dose_urban( prev_urban_1st_dose/100,   props.OTHER_VAC_4W ); // (M55) Other vaccine (4w) 1st dose
        let urban_1st_doses_total = urban_az_1st_dose + urban_other_1st_dose; // (M59) Total first dose urban 

        let prev_cumulative_urban_1st_dose =  prev_month_data["cumulative_urban_1st_dose"][props.TARGET_POP_ID]; // (L67)
        this.log({prev_cumulative_urban_1st_dose});
        let cumulative_urban_1st_dose = urban_1st_doses_total + prev_cumulative_urban_1st_dose; // (M67) =M59+L67 Cumulative 1st doses adminitered - Urban

        //this.log({cumulative_urban_1st_dose, exp_cum_urb_1st_ds:expected.cumulative_urban_1st_dose});
        if(expected) this.storeTest( {cumulative_urban_1st_dose}, expected, tests_store );

        let urban_1st_dose_p = 100 * (cumulative_urban_1st_dose / props.URBAN_POP); // M103
        /*this.log( {urban_az_1st_dose, az_exp: expected.urban_az_1st_dose, 
                    urban_other_1st_dose, oth_exp: expected.urban_other_1st_dose} );*/
        if(expected) this.storeTest( {urban_az_1st_dose, urban_other_1st_dose}, expected, tests_store );

        /*this.log( {urban_1st_doses_total, exp_urb_ds_tot:expected.urban_1st_doses_total,
                     urban_1st_dose_p, exp_urb_1st_ds_p:expected.urban_1st_dose_p });*/
        if(expected) this.storeTest( {urban_1st_doses_total, urban_1st_dose_p}, expected, tests_store );

        const get_first_dose_rural = function(prev_dose, dose_factor) {

            /*
                (M73) Rural: AZ (12w) 1st dose  =IF(L106>1,0,M30*'Static Inputs'!$D$15*Model!M35*Model!M37*Model!M39)
                    L106 = (prev_rural_1st_dose)
                    M30 = Vaccinators - rural (vaccinators_rural)
                    $D$15 = Speed of vaccination per month per healthcare worker (vaccinator_speed_month)
                    M35 = Rural Vaccine acceptance 1st Dose (rural_acceptance_first_dose)
                    M37 = Rural vaccination complication factor (RURAL_COMPLICATION_FACTOR)
                    M39 = AstraZeneca (12w) (AZ_12W)
            
                (M78) Rural: Other vaccine (4w) 1st dose |  =IF(L106>1,0,M30*'Static Inputs'!$D$15*Model!M35*Model!M37*Model!M40)
                AS above but:
                    M40 = OTHER_VAC_4W
            */

            if(prev_dose > 1) return 0;
            return props.vaccinators_rural * props.vaccinator_speed_month * rural_acceptance_1st_dose * props.RURAL_COMPLICATION_FACTOR * dose_factor;
        }

        let rural_az_1st_dose =    get_first_dose_rural(prev_rural_1st_dose/100, props.AZ_12W); // (M73)
        let rural_other_1st_dose = get_first_dose_rural(prev_rural_1st_dose/100, props.OTHER_VAC_4W); // (M78)
        let rural_1st_doses_total = rural_az_1st_dose + rural_other_1st_dose; // (M82) Total First dose rural (M73+M78)
      
        let cumulative_rural_1st_dose = prev_month_data["cumulative_rural_1st_dose"][props.TARGET_POP_ID] + rural_1st_doses_total; // M89 =L89+M82

        let rural_1st_dose_p = 0; // (M106)
        if(props.RURAL_POP) { // Avoid div by zero error on Nauru
            rural_1st_dose_p = 100 * (cumulative_rural_1st_dose / props.RURAL_POP); //  (M106) =M89/'Static Inputs'!$D$34
        }

        /*this.log({rural_az_1st_dose,"exp_az": expected.rural_az_1st_dose, rural_other_1st_dose, "exp_oth": expected.rural_other_1st_dose});
        this.log({rural_1st_doses_total, "exp": expected.rural_1st_doses_total,
                    rural_1st_dose_p, exp_rural_1st_ds_p:expected.rural_1st_dose_p });*/
        
        if(expected) this.storeTest( {rural_az_1st_dose, rural_other_1st_dose, rural_1st_doses_total, rural_1st_dose_p}, expected, tests_store );
        
        let total_1st_dose_administered = rural_1st_doses_total + urban_1st_doses_total; // (M94) = Total first dose rural + Total first dose urban = (M59+M82). 
        let cumulative_1st_dose = prev_month_data["cumulative_1st_dose"][props.TARGET_POP_ID] + total_1st_dose_administered; // (M97)  'Cumulative 1st doses adminitered - Urban' + 'Cumulative 1st doses adminitered - Rural'
        
        if(expected) this.storeTest( {total_1st_dose_administered, cumulative_1st_dose}, expected, tests_store );
        
        let total_doses_1st_p = 100 * ((cumulative_rural_1st_dose+cumulative_urban_1st_dose) / props.TOTAL_POP); // M109

        // SECOND DOSES HERE
        // !Special case var - (might need to be 3 months back in data)
        let prev_urban_az_1st_dose = three_months_ago_data["urban_az_1st_dose"][props.TARGET_POP_ID]; // (J50) !3 Months back
        let prev_urban_other_1st_dose = prev_month_data["urban_other_1st_dose"][props.TARGET_POP_ID]; // (L55)

        this.log({prev_urban_az_1st_dose, prev_urban_other_1st_dose});

        const get_2nd_dose = function(prev_dose, dose_factor, vaccinators ) {
            
            /*  Urban
                =IF(J50=0, M29*'Static Inputs'!$D$15*Model!M36*Model!M39, J50*M36)
                J50= AZ (12w) 1st dose - from 2 months back. We will use most recent instead (L50)  (prev_az_1st_dose)
                M29= Vaccinators Urban (vaccinators_urban)
                $D$15=Speed of vaccination per month per healthcare worker - (vaccinator_speed_month)
                M36=Vaccine acceptance 2nd dose (VACC_ACCEP_2ND_DOSE)
                M39="AstraZeneca (12w)" - (AZ_12W)
            */  

            // "AZ (12w) 2nd dose"            =IF(J73=0,M30*'Static Inputs'!$D$15*Model!M36*Model!M39,J73*M36)
            // "Other vaccine (4w) 2nd dose"  =IF(L78=0,M30*'Static Inputs'!$D$15*Model!M36*Model!M40,L78*M36)

            if(prev_dose == 0) return vaccinators * props.vaccinator_speed_month * props.VACC_ACCEP_2ND_DOSE * dose_factor;

            return prev_dose * props.VACC_ACCEP_2ND_DOSE; 
        }

        let urban_az_2nd_dose    = get_2nd_dose( prev_urban_az_1st_dose,    props.AZ_12W,       props.vaccinators_urban ); // (M52) "AZ (12w) 2nd dose"
        let urban_other_2nd_dose = get_2nd_dose( prev_urban_other_1st_dose, props.OTHER_VAC_4W, props.vaccinators_urban ); // (M57) "Other 2nd dose"
        
        let urban_2nd_doses_total_pre = urban_az_2nd_dose + urban_other_2nd_dose; // (M60) "Total 2nd doses adminitered - Urban"

        let urban_dose_distribution_extractor = 0; // (M64) 'Amount to extract from 2nd dose distribution'

        if(iteration > 0) {   
            // !Note: Spreadsheet has a formula only for the column/cells after first iteration of the projected data!
            
            /* Special field (M64) 'Amount to extract from 2nd dose distribution'
             =IF((N59+N60)>N63,(N59+N60)-N63,0)

                N59= Total 1st doses administered (urban_1st_doses_total)
                N60= Total 2nd doses administered (urban_2nd_doses_total_pre)
                N63= "Maximum vaccinator speed (max_urban_vaccinator_speed)
            */

            let tot_doses = urban_1st_doses_total + urban_2nd_doses_total_pre; // (N59+N60)
            let max_urban_vaccinator_speed = props.vaccinators_urban * props.vaccinator_speed_month; // (M63) = "Maximum vaccinator speed - urban"  =M29*'Static Inputs'!$D$15  ==   props.vaccinators_urban * props.vaccinator_speed_month 
             
            if(tot_doses > max_urban_vaccinator_speed) {
                urban_dose_distribution_extractor = tot_doses - max_urban_vaccinator_speed;
            }
            
        }

        let urban_2nd_doses_total = urban_2nd_doses_total_pre - urban_dose_distribution_extractor; // (M61) "Total 2nd doses adminitered - Urban" =M60-M64

        let prev_cumulative_urban_2nd_dose = prev_month_data["cumulative_urban_2nd_dose"][props.TARGET_POP_ID]; // (L69)     
        let cumulative_urban_2nd_dose = prev_cumulative_urban_2nd_dose + urban_2nd_doses_total; // (M69)
        this.log({prev_cumulative_urban_2nd_dose, cumulative_urban_2nd_dose});

        let urban_2nd_dose_p = 100 * (cumulative_urban_2nd_dose / props.URBAN_POP); // ! (104)  Needs to be cumulative_urban_doses
        
        if(expected) this.storeTest( {urban_az_2nd_dose, urban_other_2nd_dose, urban_2nd_doses_total, urban_2nd_dose_p}, expected, tests_store );

        let prev_rural_az_1st_dose = three_months_ago_data["rural_az_1st_dose"][props.TARGET_POP_ID]; // !(J73) 3 MONTHS Back
        let prev_rural_other_1st_dose = prev_month_data["rural_other_1st_dose"][props.TARGET_POP_ID]; // (L78)

        this.log({prev_rural_az_1st_dose, prev_rural_other_1st_dose});

        let rural_az_2nd_dose =    get_2nd_dose( prev_rural_az_1st_dose,    props.AZ_12W,       props.vaccinators_rural );       // (M75) "AZ (12w) 2nd dose" =IF(J73=0,M30*'Static Inputs'!$D$15*Model!M36*Model!M39,J73*M36)
        let rural_other_2nd_dose = get_2nd_dose( prev_rural_other_1st_dose, props.OTHER_VAC_4W, props.vaccinators_rural ); // (M80) "Other vaccine (4w) 2nd dose" ==IF(L78=0,M30*'Static Inputs'!$D$15*Model!M36*Model!M40,L78*M36)
        let rural_2nd_doses_total = rural_az_2nd_dose + rural_other_2nd_dose; // (M84) "Total 2nd doses adminitered - Rural"
       
        let prev_cumulative_rural_2nd_dose = prev_month_data["cumulative_2nd_dose"][props.TARGET_POP_ID] - prev_cumulative_urban_2nd_dose; // (M91) prev_month_data["cumulative_rural_2nd_dose"][props.TARGET_POP_ID]; // (L91)  
        let cumulative_rural_2nd_dose = prev_cumulative_rural_2nd_dose + rural_2nd_doses_total; // (M91)
        this.log({prev_cumulative_rural_2nd_dose, cumulative_rural_2nd_dose});

        let rural_2nd_dose_p = 100 * (cumulative_rural_2nd_dose / props.RURAL_POP); //! (M107) TODO this needs to be cumulative rural doses?

        let total_2nd_dose_administered = urban_2nd_doses_total + rural_2nd_doses_total; // (M95)  Total 2nd doses adminitered - Urban & Rural

        // TODO: Need to break rural and urbam cummulative 2nd doses up as cumulative_2nd_dose is drifting wrong compared to spread sheet.

        let cumulative_2nd_dose = prev_month_data["cumulative_2nd_dose"][props.TARGET_POP_ID] + total_2nd_dose_administered; // M98 "Cumulative 2nd doses administered - Urban & Rural"

        if(expected) this.storeTest( {rural_az_2nd_dose, rural_other_2nd_dose, rural_2nd_doses_total, rural_2nd_dose_p, total_2nd_dose_administered, cumulative_2nd_dose}, expected, tests_store );
        
        // Final figure (Cumulative 1st dose Urban + Cumulative 1st dose Rural) / population
        let total_doses_2nd_p = 100 * (cumulative_2nd_dose / props.TOTAL_POP); // M110

        // Saftey test that 2nd dose not > then 1st dose: (M112) formula =IF(M110>M109,M109,M110)
        total_doses_2nd_p = total_doses_2nd_p > total_doses_1st_p ? total_doses_1st_p : total_doses_2nd_p;

        if(expected) this.storeTest( {total_doses_1st_p,total_doses_2nd_p}, expected, tests_store );
        if(expected && tests_store.length) this.printTests(tests_store);

        const addSolvedData = function(target_data, values, POP_ID) {
            // add all new values to a data structure.
            for (const [key, value] of Object.entries(values)) {
                self.log(key, value);
                if(!target_data[key] || !Utils.isObject(target_data[key]) ) {
                    target_data[key] = {};
                }
                target_data[key][POP_ID] = value;
            }
        }

        var solved_data = { dt:new Date(dt) };
            
        addSolvedData( solved_data, 
            { 
                urban_1st_dose_p,
                urban_2nd_dose_p,
                rural_1st_dose_p,
                rural_2nd_dose_p,
                urban_az_1st_dose,
                urban_other_1st_dose,
                rural_az_1st_dose,  
                rural_other_1st_dose,
                cumulative_rural_1st_dose,
                cumulative_urban_1st_dose,
                cumulative_1st_dose,
                cumulative_2nd_dose,
                cumulative_urban_2nd_dose,
                urban_2nd_doses_total, // For dev logging only, can remove later.
                rural_2nd_doses_total, // For dev logging only, can remove later.
                total_2nd_dose_administered, // For dev logging only, can remove later.
                total_doses_1st_p,
                total_doses_2nd_p
            }, 
                props.TARGET_POP_ID );


        return solved_data;
    }


}