<template>
    <div class="country-info">
        <a class="anchor" :name='anchor' />

        <img @click="nextCountry" class="flag" :src="flag_src" />
        
        <Dropdown @selected="dropDownSelected" :auto_open="false" :selected="selected_country_index" heading="[COUNTRY]" 
            :data_provider="countries" watch_store_path="selected_country_overview_index" 
            data_key="code" class="h2" xpad="0.333em" ypad="0.175em" />

        <div class="infobox h5" v-html="info_copy"></div>

        <div @click="toggleReadMore()" class="read-more h5 clickable"> 
            {{read_more_label}} 
            <img :class="['arrow', (read_more_open ? 'reverse' : '')]" src="../assets/down_arrow_light.svg" />
        </div>

        <div class="stats columns is-multiline is-mobile">            
            <CountryStatCell :index="0" :id="get_id(0)" :value="get_population"         label="Population" />
            <CountryStatCell :index="1" :id="get_id(1)" :value="get_urbanisation"       label="Urbanisation Rate" />
            <CountryStatCell :index="2" :id="get_id(2)" :value="get_vaccinators"        label="Vaccinators (among all vaccination teams)"  @press="clickVaccinators()"/>
            <CountryStatCell :index="3" :id="get_id(3)" :value="get_acceptance_rate"    label="Vaccine Acceptance Rate" @press="clickAcceptanceRate()"/>
            <CountryStatCell :index="4" :id="get_id(4)" :value="get_sources"            label="primary sources" :is_primary_src="true" />
        </div>

    </div>

</template>

<script>

import CountryStatCell from './CountryStatCell';
import Consts from './js/Consts';
import Dropdown from './Dropdown';
import Logger from './js/Logger';
import Utils from './js/Utils';
import * as d3 from 'd3';

export default {
    name:'CountryInfoPanel',
    props: {
        anchor:  {type:String, default:'country-info'}       
    },
    data() {
        return { 
            read_more_open:false,
        }
    },
    components: {
        CountryStatCell, Dropdown
    },
    computed: {
        flag_src() {
            return require("../assets/flags/recip/" + this.id + ".svg");
        },
        id() { // get the country id/code
            return this.getFromCountry("code");
        },
        selected_country_index() {
            return this.$store.getters["selected_country_overview_index"];
        },
        get_selected_overview_county_data() {
            return (this.selected_country_index == -1) ? {} : this.countries[this.selected_country_index];
        },
        info_copy() {
            return (this.read_more_open) ? this.intro + this.readmore : this.intro;
        },
        intro() {
            return this.getFromCountry("intro");
        },
        readmore() {
            return this.getFromCountry("readmore");
        },
        get_population() {
            let v = this.getFromCountry("pop", "0");
            v = d3.format(".1s")(v);
            return v;
        },
        get_urbanisation() {
            return Math.round(this.getFromCountry(["model","static","population","urb_rate","tot"], 0) * 100) + "%"; // acceptance
        },
        get_vaccinators() {
            // if(as_str) return this.getFromCountry("vaccinators", "1"
            return Number(this.getFromCountry(["model","static","workforce","vaccinators"], 1));
        },
        get_acceptance_rate() {
            return Math.round(this.getFromCountry(["model","static","speed","acceptance"], 0)) + "%";
        },
        get_sources() {
            let d = this.getFromCountry("sources").slice(0,3); // Client directive to only show max of 3
            return d.join(",");
        },
        get_max_vaccinators() {
            return Number( this.getFromCountry(["model","static","workforce","max_vaccinators"],99) );
        },
        countries() {
            return this.$store.getters["recipient_countries"];
        },
        read_more_label() {
            return this.read_more_open ? "READ LESS" : "READ MORE";
        },
    },
    
    watch: {
        // Needs to watch for selected_country_index changes??
    },

    mounted() {
        
    },
    
    methods: {

        clickVaccinators(e) {
            Logger.log("clickVaccinators");
            this.$store.commit("slider_vaccincators_count", this.get_vaccinators);
        },

        clickAcceptanceRate(e) {
            Logger.log("clickAcceptanceRate");
            this.$store.commit("slider_acceptance_rate",  this.getFromCountry(["model","static","speed","acceptance"], 0));
        },
        
        nextCountry(e) {
            if(e.altKey) {
                // Back door advance countries to test
                Logger.log("nextCountry",e);
                let i = this.selected_country_index;
                let c = this.countries.length;
                i = (i==c-1) ? 0 : i+1;
                this.$store.commit("selected_country_overview_index", { index:i, id:this.countries[i]["code"] });
            }
        },
        
        getFromCountry(key, def) { 
            //helper
            return Utils.getValueAtPath( this.get_selected_overview_county_data, key, def );
        },

        dropDownSelected(index, id) {
            this.$store.commit("selected_country_overview_index", {index,id});
        },
        toggleReadMore() {
            this.read_more_open = !this.read_more_open;

            if(this.read_more_open == false) {
                // TODO: make better ui, scroll to the readmore button so user is not so lost. ie. inline with last view 
                let el = document.querySelector(".read-more");
                // Scroll to where this was.

            }
        },
        get_id(n) {
            switch (n) {
                case 0:     return Consts.POPULATION;
                case 1:     return Consts.URBANISATION;
                case 2:     return Consts.VACCINATORS;
                case 3:     return Consts.ACCEPTANCE_RATE;
                case 4:     return Consts.SOURCES;
                default:    return Consts.SOURCES;
            }
        }, 
    }
}
</script>

<style lang="scss"  >

    @import "../vars";
    @import "../typography";

    .country-info  {
        /*-width: 320px;*/
        text-align: left;
        
        @if($debug) {border:1px solid red;}
        
        .flag {
            width: 120px;
            height: 80px;
        }
        
        .infobox {
            padding:0em 0em;

            p {
                @include h5();
              a {
                text-decoration: underline;
                color:$highlight-blue;
                &:hover {
                    color:$acid-yellow;
                }
              }
            }

            p:first-of-type {
                /*border:1px solid red;*/
                margin-top:1em;
            }

            p:last-of-type {
                /* border:1px solid blue;*/
                margin-bottom:1em;
            }
        }
        
        .read-more {
            
            padding-bottom:.5em;
            font-weight:700;

            img {
                @if($debug) {border:1px solid darkgoldenrod}
                &.reverse{
                    transform:rotate(180deg);
                }
            }
            
            .arrow {
                width:14px;
                display: inline-block;
                
            }

        }

        .stats {
            @if($debug) {border: 1px dashed pink;}
            text-transform: uppercase;   
            padding-top:1.5em;        
        }

    }
    
</style>