/*
    Country Tooltip 
    Shown over all Graphs

*/

<template>

    <div :class="[{full_version:!lite_version}, 'country_tooltip ignore-clicks']" :style='{transform:getPosStr}'>
                
        <div class="frame columns is-multiline is-mobile is-marginless" :style='{backgroundColor:getBgCol}' >
        
            <div v-if="!lite_version" class="column flag-n-country is-full is-narrow" >

                <div class="columns is-vcentered is-mobile">
                    <div class="flag_icon column is-paddingless is-narrow">
                        <img class='icn' :src="getFlag" />
                    </div>
                    <div class="h2 column is-narrow">
                        {{title}}
                    </div>
                </div>

            </div>

            <div class="column is-paddingless is-full is-narrow">

                <div class="stat-table columns is-marginless is-mobile">
                    
                    <div class="column is-paddingless is-narrow">
                        <div class="val h5">{{date}}</div>
                        <div class="lab h6">DATE</div>
                    </div>
                    <div class="divider-line is-narrow column"> 
                        <div /> <!-- this used to make vertical line -->
                    </div>
                    <div class="column pop is-paddingless ">
                        <div class="val h5" v-html="getPopulation"></div>
                        <div class="lab h6">{{getPopulationLabel}}</div>
                    </div>
                    <div class="divider-line is-narrow column"><div /> <!-- this used to make vertical line -->
                    </div>
                    <div class="column is-narrow is-paddingless ">
                        <div class="val h5" v-html="getAmount"></div>
                        <div class="lab h6">VACCINE DOSES</div>
                    </div>
                    
                </div>
                
            </div>

        </div>
    
        <div v-if="lite_version" class="speech-arrow">
            <div class="down-arrow" :style='{borderTopColor:getBgCol, transform:get_arrow_x_offset_transform}'></div>
        </div>

    </div>

</template>

<script>

import Utils from './js/Utils';
import Consts from './js/Consts';
import Logger from './js/Logger';
import gsap from 'gsap';

export default {
    name:'CountryToolTip',
    props: {
        index:          {type:Number, default:0},
        code:           {type:String, default: Consts.COOK_ISLANDS},
        x:              {type:Number, default:0},
        y:              {type:Number, default:0},        
        title:          {type:String, default:'COOK ISLANDS'},
        date:           {type:String, default:'mar 2022'},
        pop:            {type:String, default: '100m'},
        amount:         {type:String, default:'5 million'},
        bgcol:          {type:String, default:'#00ffff'},
        lite_version:   {type:Boolean, default:false},
        centre_to_pos:  {type:Boolean, default:true},
        pop_label:      {type:String, default:"eligible population"}
    },
    data() {
        return { 
            internal_x:0,
            internal_y:0,
            current_width:0,
        }
    },
    computed: {

        getFlag() {
            return require("../assets/flags/recip/" + this.code + "_dark.svg");
        },

        getBgCol() {
            let comp = gsap.utils.splitColor(this.bgcol);
            return "rgba(" + comp[0] + "," + comp[1] + "," + comp[2] + ", .95)";
        },
        getPosStr() {
            let offset = this.centre_to_pos ? "translate(-50%,-100%)" : "translate(0%,-100%)";
            return offset + " translate(" + this.internal_x + "px," + this.internal_y + "px)";
        },
        getPopulation() {
            return Utils.nansToSpan(this.pop, "sub");
        },
        getAmount() {
            return Utils.nansToSpan(this.amount, "sub");
        },

        getPopulationLabel() {
            return this.pop_label;
        },

        is_lite() {
            return this.lite_version;
        },

        get_x() {
            return this.x; // external x
        },
        get_y() {
            return this.y; // external x
        },

        get_arrow_x_offset_transform() {
            // Offset the arrow for when the tip is edge limite
            let tx = this.x - this.internal_x;
            // TODO: might need a max of this.current_width - 18/2 (the dimms of the arrow.)
            return `translate(${tx}px,-0.5px)`; // 0.5 fixes the slight top border artefact.
        }
        
    },

    watch: {
        // Could watch for changes to x, y and adjust and sample bounds in relation to root of doc. 
        // This could maintain an internal refernce to keep it on screen.
        get_x(new_val, old_val) {
            // Logger.log("Tooltip x change", new_val, old_val);
            this.internal_x = new_val;
            let self = this;
            this.$nextTick(() => {
                self.testLimits("x");
            });
        },
        get_y(new_val, old_val) {
            // Logger.log("Tooltip y change", new_val, old_val);
            this.internal_y = new_val;
            let self = this;
            this.$nextTick(() => {
                self.testLimits("y");
            });
        }

    },

    mounted() {
        this.internal_x = this.x;
        this.internal_y = this.y;        
    },

    methods: {
        testLimits(coord) {
            // Check if this thing is off screen or not and adjust accordingly.
            let bounds = this.$el.getBoundingClientRect();
            this.current_width  = bounds.width;
            let pad = 5; // how many px either side to keep it in.
            let view_w = window.innerWidth;

            // Logger.log("testLimits", coord, bounds, view_w);

            if(coord == "x") {
                if(bounds.x < pad) {
                    this.internal_x += (bounds.x * -1) + pad;
                }else if(bounds.right > view_w-pad) {
                    this.internal_x -= (bounds.right - (view_w-pad)) + 18; // 18 scrollbar allowance
                }
            }

            if(coord == "y") {
                if(bounds.y < pad) {
                    this.internal_y += (bounds.y * -1) + pad;
                }
           }
        }
    }
}
</script>

<style lang="scss" >

    @import "../vars.scss";
    @import "~bulma/sass/utilities/_all.sass"; // To get at the mixins for responsiveness.
    
    /* $debug: true; */
    
    .country_tooltip {

        position:absolute;
        top:0px;
        left:0px;
        z-index:101;
        

        /*transform-origin: 100% 50%; *//*bottom center;*/

        &.full_version {
            
           /*transition: all 0.9s;*/

            .frame {
                width:400px;
                @include mobile {
                    width:300px;
                }
            }
        }

        .frame {
            padding: .6em;
            min-width:180px;
            max-width:400px;
            @include mobile {
                min-width:120px;
                max-width:300px;
                /*width:320px;*/
            }
        }

        color:$dark-col;

        text-align: left;
        text-transform: uppercase;
        
        .flag-n-country {
            /*border:1px dashed green;*/
            
            .columns {
               /* border:1px dashed pink;*/
            }
            .column {
                /*border:1px dashed yellow;*/
            }
        }

        .h2 {
            /*border:1px dashed red;*/
        }

        .circle_icon {
           @if $debug { border:1px dashed white; }
           img {
               width:60px;
           }
        }
        .flag_icon {
           @if $debug { border:1px dashed white; }
           img {
               width:60px;
           }
        }

        .stat-table {
            /*font-size:0.7em;*/
            
            @if $debug { border:1px dashed white; }
            padding:0 0;
            margin:0 0;
            
            .sub { /* small numbers */
               font-size:.8em;
            }
            .val {
                font-weight: 700;                
            } 
            
            .pop {
                /*max-width:200px;*/
            }

            .lab {
                /*font-size:.75em;*/
               /* font-weight: 700;*/
            }

            .divider-line {
                /*padding-top:0em;
                padding-bottom:0em;*/
                padding:0 .6em;
                div {
                    height:90%;
                    border-left:1px solid $dark-col;
                }
            }
        }

        .speech-arrow{
            /*border:1px dashed white;*/
            display:block;

            $a_dim: 18px;

            .down-arrow { /* https://css-tricks.com/snippets/css/css-triangle/ */
                margin:0 auto;
                width: 0; 
                height: 0; 
                border-left: $a_dim solid transparent;
                border-right: $a_dim solid transparent;            
                border-top: $a_dim solid;
            }
        }

    }

    .columns {
        @if $debug { border:1px dashed yellow;}
    }
    .column {
        @if $debug { border:1px dashed pink;}
    }

</style>