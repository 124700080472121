/* 
    Dropdown 

*/

<template>
    <div class="dropdown columns is-marginless is-multiline is-mobile is-vcentered" >
        <div @click="toggle()" class="column sel-header is-full clickable" :style="get_padding" >
            <div class="tit">{{getSelectionTitle()}}</div>
            <div class="arrow" :style="{right:get_x_pad,top:get_arrow_y_pad}" >
                <img :class="[(is_open ? 'reverse' : '')]" src="../assets/down_arrow_dark.svg" />
            </div>
        </div>
        <div v-if="is_open" class="column list is-full is-paddingless " >
            <div v-for="(d,i) in getDataProvider()" v-bind:key="d[get_data_key]" 
                    :data-index="i" :class="[(i == get_index ? 'selected' : ''),'item']" 
                    @click="select(i,d[get_data_key])">
                <a :style="get_padding" >{{d[get_label_key]}}</a> <!--  -->
            </div>
        </div>
    </div>
</template>


<script>

import Utils from "./js/Utils";
import Logger from "./js/Logger";

export default {
    name:'Dropdown',
    props: {
       data_provider: {type:Array, default: function() { return [{"name":"Hello","id":"a"}, {"name":"World","id":"b"}] }},
       label_key: {type:String, default:"name"},
       data_key: {type:String, default: "id"},
       auto_close: {type:Boolean, default:true},
       heading: {type:String, default: "heading"},
       auto_open: {type:Boolean, default:true},
       selected: {type:Number, default:-1}, // Default selection on create
       xpad: {type:String, default: "1.0em"},
       ypad: {type:String, default: ".5em"},
       arrow_y_pad: {type:String, default:".33em"},
       watch_store_path: {type:String, default:""},
       show_heading_when_open: {type:Boolean, default:true} // TODO: implement so when fals it shows the top item, but still the arrow, and also not the selection colour.
    },
    data() {
        return { 
            index:-1, // what item is selected?
            open:false,
        }
    },
    computed: {
        get_index() {
            return this.index;
        },
        is_open() {
            return this.open;
        },
        get_label_key(){
            return this.label_key;
        },
        get_data_key(){
            return this.data_key;
        },
        get_padding() {
            return "padding:" + this.ypad + " " + this.xpad;
        },
        get_x_pad() {
            return this.xpad;
        },
        get_y_pad() {
            return this.ypad;
        },
        get_arrow_y_pad() {
            return this.arrow_y_pad;
        },
        get_y_pad_mod() {
            let val = Number(this.ypad.match(/[+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*)(?:[eE][+-]?\d+)?/)[0]);
            return (val * 1.5) + "em";
        },
        selected_store_index() {
            if(this.watch_store_path == "") return;
            return this.$store.getters[this.watch_store_path];
        }
    },
    watch: {
        // Backdoor watch to change the selection if it changes (needed for dev advance test code)
        selected_store_index(new_val, old_val) {
            if(new_val != this.index) {
                this.index = new_val;
            }
        }
    },
    created() {
        this.open = this.auto_open;
        this.index = this.selected;
    },
    mounted() {
        
    },
    methods: {
        getDataProvider() {
            return this.data_provider;
        },
        select(index, id) {
            Logger.log("DropDown:select", index, id);
            this.index = index;
            this.$emit("selected", index, id); // broadcast
            if(this.auto_close) {
                this.toggle();
            }
        },
        toggle() {
            this.open = !this.open;
        },
        getSelectionTitle() {
            if(this.index < 0 || this.open) {
                return this.heading;
            }
            return this.data_provider[this.index][this.label_key];
        }
    }
}
</script>

<style lang="scss"  >

    @import "../vars.scss";

    /*$pad-y: .5em;
    $pad-x: 1.0em;*/

    /*$debug : true;*/

    .dropdown {
        
        background-color:$highlight-blue;
        text-transform: uppercase;

        .sel-header {
            @if($debug) {border:1px dashed red;}

            /*padding:$pad-y $pad-x;*/

            color: $dark-col;

            .tit {
                display: inline-block;
                vertical-align: text-bottom;
            }
            .arrow {
                width:24px;
                @if($debug) {border:1px solid grey;}
                display: inline-block;
                position:absolute;
                /*right:1.0em; NOW SET AT STYLE LEVEL*/
                img {
                    @if($debug) {border:1px solid darkgoldenrod}
                    transition: all .2s;
                    &.reverse{
                        transform:rotate(180deg);
                    }
                }
            }
        }
        
        .list {
            
            @if($debug) {border:1px dashed pink;}

            background-color:$mid-grey;

            .item { 
                @if($debug) {border:1px dashed green;}

                line-height: 1.0em;


                &:first-of-type {
                    a {
                        /*padding-top:$pad-y;*/
                    }
                }

                a {
                    transition: all .2s;
                    /*padding:($pad-y/2) $pad-x;*/
                    @if($debug) {border:1px dashed magenta;}
                    color:white;
                    display: block;
                    background-color:inherit;

                   /* line-height:1.0em;*/
                    &:hover {
                        color:$mid-grey;
                        background-color:$highlight-blue;
                    }
                }
                &.selected {
                    background-color:$highlight-blue;
                    a {
                        color:$dark-col;
                    }
                }
            }

        }

    }

</style>