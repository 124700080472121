/*
    Utils

*/

import * as d3 from 'd3';
import Logger from './Logger';

export default {

    createRect: function(x,y,w,h) {
        // Make a rect stucture
        let rct = {
            x:x, y:y, left:x, top:y,
            right:-1, bottom:-1,
            width:w, height:h
        }
        rct.right = x+w;
        rct.bottom = y+h;
        // centre coord
        rct.cx = rct.x+(w/2.0);
        rct.cy = rct.y+(h/2.0);
        return rct;
    },

    nansToSpan: function(str, _class) {
        // Encase all substr's that are not numbers inside <span> tags
        // e.g, "120 million" becomes "120<span class='span_class'>million</span>"
        _class = _class ? _class : '';
        
        return str.replace(/[^\d,.]+/g, function( s ) {
            return "<span class='" + _class + "'>" + s + "</span>"
        });
    },

    formatDosesAmount: function(amount) {
        // reduce amount to smallest value with a label, e.g, 12000000 becomes 12 MILLION
        
        if(amount == 0) {
            return "0";
        }

        let v = d3.format(".2s")(amount);

        if(v.includes("G")) {
            v = v.replace(/G/," Billion");    

        }else if(v.includes("k")) {            
            v = v.replace(/k/, " Thousand") 

        }else if(v.includes("M")) {            
            v = v.replace(/M/," Million");        
        }
        return v;
    },

    hexToRGB: function(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
    },

    has: function(obj, key) {
		// Wrapping Object.prototype to avoid compiling issues with some Lint configs.

		if(Array.isArray(key)) {
			// Assume keys are a items in a path
			let c = key.length;
			let exists = true;
			let ob = obj;
				for(let i=0;i<c;i++) {
					if(this.has(ob,  key[i])) {
						ob = ob[key[i]];
					}else{ 
						exists = false;
						break;
					}
				}
			return exists;			
		}

		return Object.prototype.hasOwnProperty.call(obj, key);
	},

    mkDate: function(d) { 

        // Take "YYYY-MM-DD" and return a data object

        if(d instanceof Date) return d; // already a date

        return new Date(d);
    },

    dateToMYYYY: function (dt) {
        if(dt == "") dt = new Date();
        let m = dt.getMonth();
        m = isNaN(m) ? 0 : m;
        let mn = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"][dt.getMonth()];
        return mn + " " + dt.getFullYear();
    },

    dateToSQL: function (dt) {
        const pad = function(num) { return ('00'+num).slice(-2) };
        return dt.getFullYear() + '-' +
        pad(dt.getMonth() + 1)  + '-' +
        pad(dt.getDate()); //       + ' ' +
        //pad(dt.getUTCHours())      + ':' +
        //pad(dt.getUTCMinutes())    + ':' +
        //pad(dt.getUTCSeconds());
    },

    monthDiff: function (d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    },

    isLeapYear: function (year) { 
        return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
    },
    
    getDaysInMonth: function (year, month) {
        return [31, (this.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    },
    
    addMonths:function(date, m) {
        // return date.setMonth( date.getMonth() + m );

        // Logger.log("loop addMonths", date, m);

        var d = date.getDate();
        date.setMonth(date.getMonth() + m);
        if (date.getDate() != d) {
            date.setDate(0);
        }

        /*if (date && m) {
            var m, d = (date = new Date(+date)).getDate()
        
            date.setMonth(date.getMonth() + m, 1)
            m = date.getMonth()
            date.setDate(d)
            if (date.getMonth() !== m) date.setDate(0)
        }*/

       /* var years = Math.floor(m / 12);
        var months = m - (years * 12);
        if (years) date.setFullYear(date.getFullYear() + years);
        if (months) date.setMonth(date.getMonth() + months);*/



        /*var n = date.getDate();
        date.setDate(1);
        date.setMonth( date.getMonth() + m );
        date.setDate( Math.min(n, this.getDaysInMonth(date.getFullYear(), date.getMonth()) ) );
      */

        return date;
    },


    isObject: function (objValue) {
        return objValue && typeof objValue === 'object' && objValue.constructor === Object;
    },

    getValueAtPath: function(dat, key, def) { // safe look for path references

        def = (def == undefined) ? "" : def;
        
        var res = def;

        if(Array.isArray(key)) {
            // debugger;
            let c = key.length;
            for(let i = 0; i<c; i++) {
                let k = key[i];
                if(this.has(dat, k)) {
                    if(i == c-1) { // we are at the last one
                        res = dat[k];                            
                    }else{
                        dat = dat[k]
                    }
                }else{
                    break;
                }
            }
        }else {
            res = this.has(dat, key) ? dat[key] : def;
        }
        //Logger.log("Found", res, "@", key);
        return res;
    },

    /*mkDate: function(dstr) { // Take "D/M/YYYY" and return a data object
        var prts = dstr.split("/");
        return new Date(+prts[2], prts[1] - 1, +prts[0]);
    },*/
	cloneObject: function (src) {
		return JSON.parse( JSON.stringify(src) ); 
		// Note: For shallow copy that does not need to be json safe, use : return Object.assign({}, src);
	},

    //------------------------------------------------------------------------------------------------------

    getURLParameter: function(name, def_value) {
        // Read a argument string variable.
        // if null return def_value
        // eslint-disable-next-line no-sparse-arrays
        var val = decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[,""])[1].replace(/\+/g, '%20'));

        if (val == null || val == "" || val == undefined) return def_value; // no value

        if(typeof def_value  === "boolean") {
            return (val.toLowerCase() == "true" || val == "1");
        }

        if(typeof def_value  === "number") {
            val = parseFloat(val);
            if(isNaN(val)) return def_value;
        }

        return val;
    },

    isTouchEnabled:function() {
        return ( 'ontouchstart' in window ) || 
               ( navigator.maxTouchPoints > 0 ) || 
               ( navigator.msMaxTouchPoints > 0 );
    },

    getElapsedMilliseconds: function() {
		return (new Date()).getTime() - this._app_start_time;
	},

	getElapsedSeconds: function() {
		return this.getElapsedMilliseconds() / 1000;
	},

	getUpTimeStr: function() {
		return "up:"+this.secondsToHMS( this.getElapsedSeconds() );
	},

	secondsToHMS: function(totalSeconds) {
		var hours   = Math.floor(totalSeconds / 3600);
		var minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
		var seconds = Math.round(totalSeconds - (hours * 3600) - (minutes * 60));
		return ('0'+hours).slice(-2) +":" + ('0'+minutes).slice(-2) + ":" + ('0'+seconds).slice(-2); 
	},

    _app_start_time: (new Date()).getTime(),

}