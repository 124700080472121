/*
 * Simple Logger
 * @author Adam Hinshaw
 *
 * Quick way to turn it all off is comment out the console.log line below.
 *
 */

const Logger = {

    enabled: false,

    log: function() {
       if(Logger.enabled) console.log.apply(null, arguments);
    },
    style: function() {
        // TODO: console.log('%cHi there', 'color: white; background: seagreen; padding: 15px; font-size: 18px; font-weight: bold;');
        // Do a standard log but add some styling to mae it stand out
    },
    warn: function() {
        console.warn.apply(null, arguments);
    },
    error: function() {
        console.error.apply(null, arguments);
    }, 
    table: function() {
        if(Logger.enabled) console.table.apply(null, arguments);
    }

}

export default Logger;
