
/*
    JS object sample / requirements of data format from the Lowy supplied 
    This sample taken from 


    Just provide the back data from last 3 months
    
 */

export var SolomonsExpectedData = {

    // Test data from Excel file to compare algorithm accuracy against.
    
    // TODO: Could key this on date string too so i can test a few months of projections from the spread sheet.

    "dt": "2021-10-21", 

    // table of month final doses only
    "ep_dose_p": [[30,10],[37,17],[42,23],[47,31],[50,37],[54,42],[56,47],[59,50],[61,53],[63,56],[65,58],[67,60],[69,62],[70,64],
    [72,66],[73,68],[74,69],[75,70],[77,72],[78,73],[79,74],[80,75],[81,77],[82,78],[83,79],[84,80],[85,81],[85,82],[86,82],[87,83],
    [88,84],[88,86],[89,87],[89,88],[90,88],[91,89],[91,90],[92,90],[93,91],[93,92],[94,92],[94,93],[95,94],[96,94],[96,95],[97,95],
    [97,96],[98,96],[98,97],[99,97],[99,98],[99,98],[100,99],[100,99],[100,100]
    ],

    "ep" : {
        urban_acceptance_1st_dose:.89, // M34
        rural_acceptance_1st_dose:.89, // M35

        urban_az_1st_dose: 9978.60, // M50
        urban_other_1st_dose: 2494.65, // M55
        urban_1st_doses_total: 12473.25, // M59
        cumulative_urban_1st_dose:  30870.41 , // M67 

        rural_az_1st_dose: 15234.86, //  M73
        rural_other_1st_dose: 3808.71, // M78
        rural_1st_doses_total: 19043.57, // M82

        urban_az_2nd_dose:  2245.76, // M52
        urban_other_2nd_dose: 1016.35, // M57
        urban_2nd_doses_total:  3262.11, // M61

        rural_az_2nd_dose: 6857.44, //  M75
        rural_other_2nd_dose: 3103.45, // M80
        rural_2nd_doses_total: 9960.89, // M84

        total_1st_dose_administered: 31516.82, // M94
        total_2nd_dose_administered: 13223.00, // M95

        cumulative_1st_dose: 106089.82, // M97
        cumulative_2nd_dose: 36394.00, //  M98 

        urban_1st_dose_p: 35, // M103 % Urban population w/ 1st dose
        urban_2nd_dose_p: 10, // M104 % Urban population w/ 2nd dose
        rural_1st_dose_p: 28, // M106 % Rural population w/ 1st dose
        rural_2nd_dose_p: 10, // M107 % Rural population w/ 2nd dose

        total_doses_1st_p: 30, // M109 
        total_doses_2nd_p: 10 // M110
    },
    "tp" : {
        urban_acceptance_1st_dose:.94, // M121
        rural_acceptance_1st_dose:.94, // M122

        urban_az_1st_dose:  10552.19, // M
        urban_other_1st_dose: 25269.92, // M55
        urban_1st_doses_total: 0, // M59
        cumulative_urban_1st_dose:  0, // M67 

        rural_az_1st_dose: 0, //  M73
        rural_other_1st_dose: 0, // M78
        rural_1st_doses_total: 0, // M82

        urban_az_2nd_dose: 0, // M52
        urban_other_2nd_dose: 0, // M57
        urban_2nd_doses_total: 0, // M61

        rural_az_2nd_dose: 0, //  M75
        rural_other_2nd_dose: 0, // M80
        rural_2nd_doses_total: 0, // M84

        total_1st_dose_administered: 0, // M94
        total_2nd_dose_administered: 0, // M95

        cumulative_1st_dose: 0, // M97
        cumulative_2nd_dose: 0, //  M98 

        urban_1st_dose_p: 0, // M103 % Urban population w/ 1st dose
        urban_2nd_dose_p: 0, // M104 % Urban population w/ 2nd dose
        rural_1st_dose_p: 0, // M106 % Rural population w/ 1st dose
        rural_2nd_dose_p: 0, // M107 % Rural population w/ 2nd dose

        total_doses_1st_p: 0, // M109 
        total_doses_2nd_p: 0 // M110
    },
    "tot" : {
        urban_acceptance_1st_dose:0, // M34
        rural_acceptance_1st_dose:0, // M35

        urban_az_1st_dose:0, // M50
        urban_other_1st_dose: 0, // M55
        urban_1st_doses_total: 0, // M59
        cumulative_urban_1st_dose:  0, // M67 

        rural_az_1st_dose: 0, //  M73
        rural_other_1st_dose: 0, // M78
        rural_1st_doses_total: 0, // M82

        urban_az_2nd_dose: 0, // M52
        urban_other_2nd_dose: 0, // M57
        urban_2nd_doses_total: 0, // M61

        rural_az_2nd_dose: 0, //  M75
        rural_other_2nd_dose: 0, // M80
        rural_2nd_doses_total: 0, // M84

        total_1st_dose_administered: 0, // M94
        total_2nd_dose_administered: 0, // M95

        cumulative_1st_dose: 0, // M97
        cumulative_2nd_dose: 0, //  M98 

        urban_1st_dose_p: 0, // M103 % Urban population w/ 1st dose
        urban_2nd_dose_p: 0, // M104 % Urban population w/ 2nd dose
        rural_1st_dose_p: 0, // M106 % Rural population w/ 1st dose
        rural_2nd_dose_p: 0, // M107 % Rural population w/ 2nd dose

        total_doses_1st_p: 0, // M109 
        total_doses_2nd_p: 0 // M110
    }
};

        /**
         * I've attempted to use only the core data we need.
         * !NOTE: Need to pay attention to things that are % (0 to 100) or fractions (0.0 to 1.0)
         */

export var TestData = {   

    "model": {

        "static" : {   // Data from the 'Static Inputs' sheet tab

            "workforce": {
                "vaccinators": 63, // (D7)
                "max_vaccinators" : 133, // This to come from existing client provided source.
            },
            
            "distrubution": { // Note: from Model sheet
                "az":.8, // (M39)
                "other": .2 //  (M40)
            }, 

            "speed" : { // Vaccination speed
                "per_worker": 30,   // (D14) [MIGHT NOT BE NEEDED IN FRONT END]
                "per_month" : 900,  // (D15) == per_worker * 30
                "acceptance": 81.5   // (D17) Note: fraction here!  (Hero value that gets manipulated in ui)
            },

            "population" : {
                "total": {
                    "ep": 355889.80, // 18+ (D31)
                    "tp": 443433.40, // 12+ (D37)
                    "tot": 686878.00 // Total (D43)
                },
                "urb_rate": {
                    "ep": .2467,  // (D32) NOTE: needs to be the raw data, not the Excel UI round up stuff. Must be put in as a fraction, not a %
                    "tp": .2467,  // (D38)
                    "tot": .2467  // (D44)
                }
            },
           
        },
        
        "actual" : { 

            // Previous ACTUAL monthly data from the "Model" tab.
            // Note: Only fields specified are required to save file size and confusion

            "months" : [

                // Note: First 2 months only need the urban and rural az 1st does values as these are 
                // required for the core algrorithm, that references these from 3 months past of running date

                { 
                    // 1st historical month (3 months back from current)
                    "dt":"2021-07-01", // JUL 
                    "urban_az_1st_dose":  { 
                        "ep":  2245.76, // (J50) 
                        "tp":  2245.76, // (J137) 
                        "tot": 2245.76  // (J224) 
                    },
                    "rural_az_1st_dose":  { 
                        "ep":  6857.44, // (J73)
                        "tp":  6857.44 ,// (J160)
                        "tot": 6857.44  // (J247)
                    },
                },

                { 
                    // 2nd historical month 
                    "dt":"2021-08-01", // AUG 
                    "urban_az_1st_dose": { 
                        "ep":  4844.20, // (K50) 
                        "tp":  4844.20, // (K137) 
                        "tot": 4844.20  // (K224) 
                    },
                    "rural_az_1st_dose":  { 
                        "ep":  14791.80, // (K73)
                        "tp":  14791.80, // (K160)
                        "tot": 14791.80  // (K247)
                    },
                },

                {
                    // 3rd, last/most recent historical month. 
                    // Currently SEP - But must be the most recent month of ACTUAL recorded data. 
                    // It needs all the required fields.

                    "dt":"2021-09-01", 
                    "urban_az_1st_dose": { 
                        "ep":  4065.42, // (L50) 
                        "tp":  4065.42, // (L137) 
                        "tot": 4065.42, // (L224)
                    },
                    "rural_az_1st_dose": { 
                        "ep":  12413.78, // (L73)
                        "tp":  12413.78, // (L160)
                        "tot": 12413.78 ,// (L247)
                    },
                    "urban_1st_dose_p": { 
                        "ep":20.95395821965110, // (L103)
                        "tp":17, // (L190)
                        "tot":11 // (L277)
                    },
                    "urban_2nd_dose_p": { 
                        "ep":6.51072326321237, // (L104)
                        "tp":5, // (L191)
                        "tot":3 // (L278)
                    },
                    "rural_1st_dose_p": { 
                        "ep": 20.95395821965110, // (L106)
                        "tp": 17, // (L193)
                        "tot":3 // (L280)
                    },
                    "rural_2nd_dose_p": { 
                        "ep": 6.51072326321237, // (L107)
                        "tp": 5, // (L194)
                        "tot":3 // (L281)
                    },
                    "urban_other_1st_dose": { 
                        "ep": 1016.35, // (L55)
                        "tp": 1016.35, // (L142)
                        "tot":1016.35  // (L229)
                    },
                    "cumulative_rural_1st_dose": {  
                        "ep": 56175.84, // (L89)
                        "tp": 56175.84, // (L176)
                        "tot": 56175.84 // (L263)
                    },
                    "cumulative_urban_1st_dose": { 
                        "ep": 18397.16, // (L67) !Note Might not need if i can calculate from other fields
                        "tp": 18397.16, // (L154)
                        "tot":18397.16  // (L241)
                    },
                    "cumulative_urban_2nd_dose": { // Cumulative 2nd doses administered - Urban
                        "ep": 5716.29, // (L69) 
                        "tp": 5716.29, // (L156)
                        "tot":5716.29  // (L243)
                    },
                    "rural_other_1st_dose": { 
                        "ep": 3103.45, // (L78)
                        "tp": 3103.45, // (L165)
                        "tot":3103.45  // (L252)
                    },
                    "cumulative_1st_dose": { 
                        "ep": 74573.00, // (L97)  "Cumulative 1st doses adminitered - Urban & Rural"
                        "tp": 74573.00, // (L184)
                        "tot":74573.00  // (L271)
                    },
                    "cumulative_2nd_dose": { 
                        "ep": 23171.00, // (L98) 
                        "tp": 23171.00, // (L185)
                        "tot":23171.00  // (L272)
                    },
                }
            ]

        }

    }

};
