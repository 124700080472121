<template>

    <section class="credits ">        

        <a class="anchor" :name='anchor' />

        <div class="inner-col">

            <div class="text-col">
            
                <div class="columns is-multiline is-vcentered">

                    <div class="h1 column is-full" style="align-self:flex-start" > LOWY INSTITUTE </div>

                   <!-- <div class="column is-full"><img class='lowy-logo' src="../assets/lowy-logo.svg" /></div> -->
                    
                    <div class="column is-full h5" style="align-self:flex-end">
                        <div class="copy" v-html="pvGetContent('about')" ></div>
                    </div>

                    <div class="column is-full h4 footer">
                        <div class="platform">
                            <div>PLATFORM + DESIGN</div>
                            <div class="h5">
                                <a class="h5" href="http://gliderglobal.com" >GLIDER</a>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        
        </div>

    </section>

</template>

<script>

import Logger from './js/Logger'

export default {
    name:'Credits',
    props: {
       anchor:  {type:String, default:'credits'}           
    },
    data() {
        return { 
        }
    },
    computed: {
    },
    watch: {
    },
    mounted() {
        
    },
    methods: {

        build() {
            Logger.log("build", this.anchor);
        },

    }
}
</script>

<style lang="scss"  >

    @import "../vars.scss";
    
    .credits {

         background-image:
            linear-gradient(to bottom, rgba(36, 42, 54, 1.0), rgba(36, 42, 54, .52)), /* mid-grey: 242a36 */
            url("../assets/bg_img.jpg");
        background-position: top center;
        /*	background-size: cover; contain?*/
        background-repeat: no-repeat;
        
        margin-bottom: 0;

        text-align:center;
        /*border:1px solid red;*/
        
        .lowy-logo {
            width:400px;
        }


        >.columns {
            min-height:100vh;
        }
        .h4 {
            /*border: 1px solid brown;*/
        }
        p {
            margin-top:0.25em;
        }

        a, a:visited {
            color: $acid-yellow;
        }
        a:hover {
            color:$highlight-blue;
        }
        
        .footer {
            /*font-size:.66em;*/
            background-color:inherit;
            
            align-self: flex-end; /* ?? get it to sit at bottom */
            color:white;


            .platform {
                margin-top:2.0em;
            }
        }
        
    }

</style>