
<template>
    <section class="reg-ov inner-col">
        
        <a class="anchor" :name='anchor' />

        <div v-if="!is_embedded" class="text-col">
            <div class="h1">REGIONAL OVERVIEW</div>
            <div class="copy" v-html="pvGetContent('regional_overview')"></div>
        </div>
        
        <div class="chart">
            <VaccinationGraph ref="graph" />
        </div>

    </section>
</template>

<script>

import VaccinationGraph from './VaccinationGraph';
import Logger from './js/Logger'

export default {
    name:'RegionalOverview',
    props: {
       anchor:  {type:String, default:'regional-overview'}
    },
    data() {
        return {
        }
    },
    components: {
        VaccinationGraph
    },
    computed: {
        is_embedded() {
            return this.$store.getters["is_embedded"];
        }
    },
    watch: {
    },
    mounted() {
        
    },
    methods: {
        build() {
            Logger.log("build", this.anchor);
            this.$nextTick( () => {
                this.$refs.graph.updateGraph(true, true);
            });
        },
    }
}
</script>

<style lang="scss" >

    @import "../vars.scss";

    .reg-ov {
        text-align: left;

        .text-col {
            margin:0 auto;
            padding-bottom:$section_copy_bottom_pad;            
        }

        .chart {
            display:block;
            width:100%;
            
        }
    }
    
</style>