/**
 * 
 * Custom plugin Component UUID generator mixin: 
 * from https://stackoverflow.com/questions/34950867/vue-js-how-to-set-a-unique-id-for-each-component-instance
 */

const UUIDGeneratorPlugin = {
    install(Vue){

        // Assign a Unique id to each component
        let uuid = 0;
        Vue.mixin({
        beforeCreate: function() {
            this.uuid = uuid.toString();
            uuid += 1;
        },
        });
    
        // Generate a component-scoped id
        Vue.prototype.$id = function(id) {
            return "uid-" + this.uuid + "-" + id;
        };

    } 
};

export default UUIDGeneratorPlugin;
