<template>

    <section class="home">

        <a class="anchor" :name='anchor' />

        <div class="inner-col" >

            <div class="text-col">
                    
                <div class="columns is-multiline is-vcentred body">

                    <div class="virus_icon column is-full is-paddingless" style="align-self:flex-end;" >
                        <img class="rotate" src="../assets/virus_icon.png"/><!-- virus image -->
                    </div>

                    <div class="column is-full" style="align-self:flex-start" >
                        <div class="tit">
                            <div class="h2_5" >LOWY INSTITUTE</div>
                            <div class="h0" > VACCINATING THE PACIFIC</div>
                        </div>
                        <div class="copy" v-html="pvGetContent('home')" ></div>
                    </div>
                    
                    <!--
                    <div class="column is-full h2_5" style="align-self:flex-end" >LOWY INSTITUTE</div>
                    <div class="column is-full h0" style="align-self:flex-start">VACCINATING THE PACIFIC</div>
                    <div class="column is-full copy" style="align-self:flex-start" v-html="pvGetContent('home')" ></div>
                    -->

                    <div class="footer column is-full">
                        <a v-if="data_loaded" class="scroll-down-indicator" @click="arrowClicked" >
                            <div>
                                <svg class="bounce" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="38.18px"
                                    height="20.63px" viewBox="0 0 38.18 20.63" style="enable-background:new 0 0 38.18 20.63;" >
                                    <polyline class="st0" points="0.77,0.77 19.09,19.09 37.41,0.77 "/>
                                </svg>
                            </div>
                        </a>
                    </div>     

                </div>

            </div>

        </div>

    </section>

</template>
<script>

import Logger from './js/Logger'

export default {
    name:'Home',
    props: {
       anchor:  {type:String, default:'home'}
    },
    data() {
        return { 
        }
    },
    computed: {
        data_loaded() { 
            return this.$store.getters["data_loaded"]; 
        }
    },
    watch: {
    },
    mounted() {
        
    },
    methods: {
        build() {
            Logger.log("build", this.anchor);
        },
        arrowClicked() {
            this.$emit('downArrowClicked');
        },
    }
}
</script>

<style lang="scss" >

    @import "../vars.scss";
    @import "../typography.scss";
    @import "~bulma/sass/utilities/_all.sass"; // To get at the mixins for responsiveness.
    
    /*$debug :true;*/

    .home {

        @if $debug {border:1px dashed red;}

        background-image:
            linear-gradient(to bottom, rgba(36, 42, 54, 0.52), rgba(36, 42, 54, 1.0)), /* mid-grey: 242a36 */
            url("../assets/bg_img.jpg");

        background-position: top center;
        /*	background-size: cover; contain?*/
        background-repeat: no-repeat;
            
        /** override section paddings */
        margin-top:0px;
        padding-top:4.0em;

        min-height: 100vh;
        display:flex;
        flex-direction:column;
        text-align:center;

        .h0{
            @include mobile {
                font-size:2.5em;
                line-height: 1.2em;
            }
        }
        .h2_5 {
            @include mobile {
                font-size:1.3em;
            }
        }

        .column {
            @if $debug { border:1px dashed pink; }
        }

        .virus_icon {

            img {
                width:140px;
                @include mobile {
                    width:80px;
                }
                mix-blend-mode: screen color-dodge;
                opacity: .8;
                
                &.rotate {
                    animation: rotation 26s infinite linear;
                }

                @keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(359deg);
                    }
                }
            }
        }

        >.columns {
        }
        
        .body {
            /*justify-content: center;*/
           min-height:100vh;
        }

        .copy {
            p {
                text-align: left;
                @include h3()
            }
        }

        .footer {
            background-color: inherit;
            position:relative;
            bottom:0px;
            align-self:flex-end;
        }

        .scroll-down-indicator {        

            &:hover {
                svg {
                    transform:scale(1.2);
                    .st0 {
                        stroke:white;
                    }
                }
            }
            svg {
                transition: all .3s;
                .st0 {
                    fill:none;
                    stroke:#CBFF00;
                    stroke-width:2.182;
                    stroke-miterlimit:10;
                }
            }

            .bounce {
                    animation: bouncer 1.5s infinite linear;
                }

                @keyframes bouncer {
                    from {
                        transform: scale(2);
                    }
                    to {
                        transform: scale(1);
                    }
                }
        
        }

    }


</style>