
<template>
    <section class="vac-srcs inner-col">
        <a class="anchor" :name='anchor' />

        <div class="text-col">
            <div class="h1">VACCINE SOURCES</div>
            <div class="copy" v-html="pvGetContent('vaccine_sources')"></div>
        </div>

        <div class="src-table text-col">
            <div class="columns is-multiline is-centered is-paddingless is-mobile"> 
                <div v-for="(d,i) in sources" v-bind:key="d.code" class="cell column is-half-desktop is-full-tablet is-full-mobile"> <!--  -->
                    <SourceCell :index="i" :id="d.code" :scale="d.scale" :title="d.name" :date="d.date" :amount="d.doses" />             
                </div>
            </div>
        </div>

    </section>
</template>

<script>

import * as d3 from 'd3';
import SourceCell from './SourceCell';
import Consts from './js/Consts';
import Logger from './js/Logger';
import Utils from './js/Utils';
import gsap from 'gsap';

export default {
    name:'VaccineSources',
    props: {
       anchor:  {type:String, default:'vaccine-sources'}
    },
    components: {
        SourceCell
    },
    data() {
        return { 
            countries: [],
            build_tl:null
        }
    },
    computed: {
        sources() {
            return this.countries;
        }
    },
    watch: {
    },
    created() {
        this.countries = this.$store.getters["source_countries"];

        const scaleAccessor = (d) => d.doses;
        const sizeScale = d3.scalePow()  // scalePow scaleLinear
                            .range([1.0,2.0])
                            .domain( d3.extent(this.countries, scaleAccessor) ); // Note: extent caluclates the min/max of the data based on the dose key

        for(let i = 0; i < this.countries.length; i++) {
            this.countries[i]["scale"] = sizeScale( scaleAccessor(this.countries[i]) );
        }

        this.countries = this.countries.sort( (a,b) => b.scale - a.scale );
        
        Logger.log("Post processed source_countries", this.countries);
    },
    mounted() {
        this.createBuild();
    },
    methods: {
        build() {
            Logger.log("VaccineSources::build", this.anchor);
            this.createBuild();
            //this.build_tl.seek(0,true); // rewind it
            //this.build_tl.pause();
 
        },
        createBuild() { 

            if(this.build_tl) {
                this.build_tl.kill();
            }

            this.build_tl = gsap.timeline({delay:1, paused:false});

            this.build_tl.fromTo(
                ".src-table .source_cell .circle_icon", 
                {scale:.33, autoAlpha:0 }, 
                {scale:1, autoAlpha:1, duration:1.5, stagger: 0.2, ease:"elastic.out"}
            );
            this.build_tl.fromTo(
                ".src-table .source_cell .flag_icon", 
                {y:"+5", autoAlpha:0 }, 
                {y:0, autoAlpha:1, duration:.5, stagger: 0.2, ease:"sine.inOut"},
                "-0.25"
            );
            this.build_tl.fromTo(
                ".src-table .source_cell .info", 
                {autoAlpha:0 }, 
                {autoAlpha:1, duration:.5, stagger: 0.2,  ease:"sine.inOut"},
                "-=2.5"                
            );
            //this.build_tl.from(".src-table .source_cell", {scale:0, autoAlpha:0, delay:1, duration:2, ease:"sineInOut"});
        
        }
    }
}
</script>

<style lang="scss"  >

    @import "../vars";
    @import "~bulma/sass/utilities/_all.sass"; // To get at the mixins for responsiveness.
    
    /*$debug:true;*/

    .vac-srcs {

        .text-col {
            padding-bottom:$section_copy_bottom_pad;
        }

        .src-table {

            @if $debug {border:1px solid limegreen;}
            
            .cell {
                @include mobile {
                    padding-left:1.8em;
                }
                @include tablet {
                    padding-left:2.5em;
                }
            }

            &> .columns { 
                padding:4.0em;
                justify-content: flex-start;
                @if $debug { border:1px solid red; }
                @include mobile {
                   /* padding:0em auto;*/
                }                
            };

            .column:first-of-type {
                @if $debug {border:1px dashed yellow;}
                padding-bottom:2.0em;
            }
        }
    }
    

</style>