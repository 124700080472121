/*
   CountryStatCell
*/

<template>
    <div :class="['stat-cell', 'column', width_class]" @click="$emit('press')" >
        <div class="columns is-mobile" >
            <div class="column is-narrow" >
                <img class="icn" :src="icon" />
            </div>            
            <div v-if="is_primary_src" class="column b prim-src">
                <div class="lab h6" >{{label}}</div>
                <div class="val c-name-tgl" v-html="splitValues()"></div>
            </div>
            <div v-else class="column b" >
                <div class="val cs-stat-num" v-html="get_value_html"></div>
                <div class="lab h6" >{{label}}</div>
            </div>

        </div>
    </div>
</template>

<script>

import Consts from "./js/Consts";
import Utils from "./js/Utils";

export default {
    name:'CountryStatCell',
    props: {
       index:           {type:Number, default:0},
       id:              {type:String, default:Consts.SOURCES},
       value:           {default:""},
       label:           {type:String, default:""},
       is_primary_src:  {type:Boolean, default:false}
    },
    data() {
        return { 
        }
    },
    computed: {
        icon() {
            return require("../assets/country/" + this.id + ".svg");
        },
        width_class() {
            return this.is_primary_src ? "is-full" : "is-half";
        },
        get_value_html() {
            // Extract any alpha "units" to a span
            return Utils.nansToSpan(String(this.value), "h6");
        }
    },
    watch: {
    },
    mounted() {
        
    },
    methods: {
        splitValues() {
            return this.value;
            // return this.valueToBrLines(); 
        },
        valueToBrLines() {
            return this.value.split(",").join("<br/>")
        }
    }
}
</script>

<style lang="scss"  >

    @import "../vars.scss";

    .stat-cell  {
        /*border:1px solid $acid-yellow;*/
        
        .column {
            padding-bottom:0em;
        }
        
        .icn {
            width:32px;
        }
        .val {
            span {
                &.h6 {
                    font-weight: 700;
                }
            }
        }

        .prim-src {
            .val {
                line-height:1em;
            }
        }

        .lab {

        }
        .column {
            &.b {
                padding-left:0;
            }
            /*border:1px dashed yellow;*/
        }
        
    }
    
</style>