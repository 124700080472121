<template>
    <section class="methodology inner-col">

        <a class="anchor" :name='anchor' />
        
        <div class="text-col" >
            <div class="h1">METHODOLOGY</div>
            <div class="copy" v-html="pvGetContent('methodology')"></div>

        <div class="download h4">
            <a href="LowyInstitute-VaccineRolloutModel.zip">DOWNLOAD THE MODEL HERE</a>
        </div> 

        <!--
                <div class="h1">INFORMATION</div>
                <div class="copy" v-html="pvGetContent('information')" ></div>
            -->
        </div>

    </section>
</template>

<script>

import Logger from './js/Logger'

export default {
    name:'Methodology',
    props: {
       anchor:  {type:String, default:'methodology'}           
    },
    data() {
        return { 
        }
    },
    computed: {
    },
    watch: {
    },
    mounted() {
        
    },
    methods: {
        
        build() {
            Logger.log("build", this.anchor);
        },

    }
}
</script>

<style lang="scss" scoped >

    @import "../vars.scss";
    
    .methodology {
        &.text-col {
            padding-bottom:$section_copy_bottom_pad;
        }
        
        .download {
            a {
                border-bottom:3px solid $acid-yellow;
                padding-bottom:0.2em;
                &:hover {
                    border-bottom:3px solid white;
                }
            }
            margin-bottom:4.0em;
        }
    }

</style>