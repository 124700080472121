
<template>
    <div class="factor-adjustor">
        <div class="columns is-multiline" >

            <div :class="[{disabled:!using_model},'tit h4 column is-full']">WHAT FACTORS WOULD ACCELERATE VACCINATION?</div>

            <!-- 0..max_vaccinator  :current="get_vaccinators"  -->
            <Slider class="column is-half"
                :end="get_max_vaccinators" 
                label="VACCINATORS" 
                :use_value_store="true" value_store_path="slider_vaccincators_count"
                :thumb_icon_url="get_vaccinators_icon" 
                :enabled="enabled_vaccinators" /> 
            
            <!-- 0..100%  :current="get_acceptance"  -->
            <Slider class="column is-half"
                :end="100"                   
                label="VACCINE ACCEPTANCE RATE" value_append="%" 
                :use_value_store="true" value_store_path="slider_acceptance_rate"
                :thumb_icon_url="get_acceptance_rate_icon"
                :enabled="enabled_acceptance" /> 

        </div>
    </div>
</template>

<script>

import Dropdown from './Dropdown';
import Logger from './js/Logger';
import Consts from './js/Consts';
import Slider from './Slider';

export default {
    name:'FactorAdjustor',
    props: {
    },
    data() {
        return { 
        }
    },
    components: {
       Slider
    },
    computed: {
        countries() {
            return this.$store.getters["recipient_countries"];
        },
        selected_country_index() {
            return this.$store.getters["selected_country_overview_index"];
        },
        is_selected() {
            return this.selected;
        },
        get_vaccinators_icon() {
            return this.getSliderImage(Consts.VACCINATORS);
        },
        get_acceptance_rate_icon() {
            return this.getSliderImage(Consts.ACCEPTANCE_RATE);
        },
        get_acceptance() {
            return this.$store.getters["slider_acceptance_rate"];
        },
        get_vaccinators() {
            return this.$store.getters["slider_vaccincators_count"];
        },
        get_max_vaccinators() {
            let i = this.selected_country_index;            
            return this.countries[i]["model"]["static"]["workforce"]["max_vaccinators"];
        },
        enabled_vaccinators() {
            return Consts.USE_MODEL && !this.is_country_view_completed;
        },
        enabled_acceptance() {
            return Consts.USE_MODEL && !this.is_country_view_completed;
        },
        is_country_view_completed() {
            return this.$store.getters["selected_country_pop_view_complete"];
        },
        using_model() {
            return Consts.USE_MODEL
        }
    },
    watch: {    

    },
    mounted() {
        
    },
    methods: {
        getSliderImage(id) {
            return require("../assets/country/" + id + ".svg");
        },
    }
}
</script>

<style lang="scss"  >

    @import "../vars.scss";

    .factor-adjustor  {

        .disabled {
            opacity:.2;
        }
        .tit {
                color:$acid-yellow;
                padding-bottom:.5em;
            }
        @if($debug) {border:1px solid white;}
    }
    
</style>