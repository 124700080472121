import Vue from 'vue'
import App from './App.vue'
import store from './store'

// Add way to get at constants from any template.
import Consts from "@/components/js/Consts"
Vue.use(Consts);

// UUID plugin 
import UUIDGeneratorPlugin from '@/components/js/UUIDGeneratorPlugin'
Vue.use(UUIDGeneratorPlugin);

// Helpers
Vue.mixin({
  methods: {
    pvGetContent: function ( key ) {
       return store.getters["content"][key];
    },
  },
})


Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
