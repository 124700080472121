/*
    Slider component
    Based on gsap/Draggable 

    + "/" + get_max

 */

<template>
    <div :id="id" :class="['pv_slider', {enabled:get_enabled}]">
         
        <input v-if="debug" ref="debugInput" class="debug" />

        <div class="txt" >
            <div class="lab h5">{{get_label}}</div>
            <div class="val cs-stat-num">{{get_display_value()}}<span class="sub">{{get_value_append}}</span></div>
        </div>

        <div v-if="shown" class="widg">
            
            <div ref="sliderTrack" class="track" @click="trackClick">
                <div ref="sliderFill" class="bg"></div>
                <div ref="sliderFill" class="fill"></div>                
            </div>
         
           <div ref="sliderThumb" class="thumb " >
                <img :src="get_thumb_image" />
            </div>

        </div>


    </div>
</template>

<script>

import Utils from "./js/Utils";
import Logger from "./js/Logger";

import {gsap} from "gsap"
import {Draggable} from "gsap/Draggable"

export default {
    name:'Slider',
    props: {
        enabled:             {type:Boolean, default:true},
        start:               {type:Number, default:0},
        current:             {type:Number, default:12},
        end:                 {type:Number, default:100},
        steps:               {type:Number, default:0}, // Increment steps / snap too. <2 = no steps
        label:               {type:String, default:"Slider"},
        value_append:        {type:String, default:""},
        thumb_icon_url:      {type:String, default:""},
        use_value_store:     {type:Boolean, default:false},
        value_store_path:    {type:String, default:""},
        value_display_multiplier:    {type:Number, default:1}, // Modify value in results (convert decimal to percantage?)
        update_store_on_release:     {type:Boolean, default:true}
    },
    data() {
        return { 
            shown:false,
            last_pos: null,
            thumb_dim:20,
            dragging:false,
            tweening:false,
            tmp_display_value:0 // Used for using use_value_store and update_store_on_release to display updates.
        }
    },
    computed: {
        id() {
            return this.$id('slider');
        },
        debug() {
            return false;
        },
        is_snap() {
            return this.steps > 1;
        },
        is_single() {
            return this.steps == 1;
        },
        get_thumb_image() {
            return this.thumb_icon_url; // use the 
        },
        get_label() {
            return this.label;
        },
        get_value() {
            let p = (this.last_pos) ? this.last_pos.p : 0;
            let range = this.end - this.start;
            return this.start + Math.round(range * p); // .toFixed(2);
        },
        get_max() {
            return this.end;
        },
        get_value_append() {
            return this.value_append;
        },
        get_enabled() {
            return this.enabled;
        },
        store_value() {
            if(this.use_value_store) {
                return this.$store.getters[this.value_store_path];
            }
            return 0;
        }

    },
    watch: {    
        store_value(new_val, old_val) {
            if(this.use_value_store) {
                let n = this.store_value;
                let range = this.end - this.start;
                let p = n/range;
                Logger.log("Slider.store_value changed",{new_val, old_val, n, p, range});
                this.positionAtDecimal( p );                
            }
        }
    },
    created() {
        gsap.registerPlugin(Draggable);
    },
    mounted() {
        this.shown = true;
        window.addEventListener('resize', this.handleResize);
        if(this.enabled) {
            setTimeout(this.initSliderInteractivity.bind(this), 400);
        }else{
            this.initialPosition();
        }
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    },

    methods: {

        get_display_value() {

            if(this.use_value_store) {

                if((this.tweening || this.dragging) && this.update_store_on_release) {
                    // return the tmp dragging value that is not going to write to the store.
                    return Math.round(this.tmp_display_value);
                }

                return Math.round(this.store_value * this.value_display_multiplier);

            }else{
                return Math.round(this.get_value * this.value_display_multiplier);
            }            
        },

        handleResize() {
            let d = this.getDragger();
            if(d) {
                // position the playhead to its last position?
                d.applyBounds(this.$refs.sliderTrack); // update bounds

                if(this.is_snap) {
                    this.positionAtIndex(this.last_pos.index);
                }else{
                    this.positionAtDecimal(this.last_pos.p);
                }
            }
        },
        updated( from_release_or_tween_complete ) { 
            // called when slider / thumb is updated.
            
            this.last_pos = this.getPos(); // store position for use in resizing
            this.debugPos( this.last_pos );
            let p = Math.trunc(Math.round(this.last_pos.p * 100)) + "%";
            this.$refs.sliderFill.style.width = p;

            if( this.use_value_store ) { 
                let can_update = true;
                
                if(this.update_store_on_release) {
                    can_update = (from_release_or_tween_complete == true);                   
                }
                
                if(can_update) {
                    this.tmp_display_value = this.get_value;
                    this.$store.commit(this.value_store_path, this.get_value);
                }else{
                    // need to store it as a value to display
                    this.tmp_display_value = this.get_value;
                }
            }

            this.$emit("updated", {value:this.get_value, ...this.getPos()});
        },

        getPos() {
            let d = this.getDragger();
            if(d == null) return -1;

            // Get the current position of the slider as number of ty[es]
            let min = d.minX;
            let max = d.maxX;
            let x =  d.x;
            let index = this.getNearestIndex(x, min, max);

            let p = (x-min) / (max-min);

            if(this.is_snap) {
                // set p to absolute % val of the index
                p = index/(this.steps-1);
            }

            let bit = Math.ceil(255*p).toFixed(0);

            // Logger.log("Slider:getPos", x, min,max, p, bit);

            return {index:index, p:p, x:x, intVal:bit};
        },

        getDragger() {
            // Get our instance of Draggable plugin
            const sel = "#" + this.id + " .thumb";
            return Draggable.get(sel);
        },

        trackClick(e) {

            if(!this.enabled) return;

            // Move the thumb to this position
            const d = this.getDragger();

            // this.$refs.sliderThumb
            let rect = e.target.getBoundingClientRect();
            let x = e.clientX - rect.left;

            x += this.thumb_dim / 2;

            // limit to the bounds of teh dragger
            x = Math.min( Math.max(d.minX, x), d.maxX );

            Logger.log("trackClick", x, d, e);

            if(this.is_snap) {
                // this.positionAtIndex( this.getNearestIndex(x, d.minX, d.maxX) );
                const index = this.getNearestIndex( x, d.minX, d.maxX );
                this.thumbTweenTo( this.getIndexToPosX( index, d.minX, d.maxX ) )
            }else{
                //this.positionAtX(x);
                this.thumbTweenTo(x);
            }

        },

        posChanged() {
            if(!this.enabled) return;
            this.$emit('sliderChanged');
        },

        positionAtX( x ) {
            const d = this.getDragger();
            gsap.set(this.$refs.sliderThumb, {x:x});
            d.update();
            this.updated();
        },

        positionAtDecimal( n ) {
            // Position slider at 0..1 percentage along track
            const d = this.getDragger();
            if(d) {
                let x = this.getPosToX(n, d.minX, d.maxX);

                Logger.log("Slider:positionAtDecimal", n, x);
                this.positionAtX(x);
            }
        },

        positionAtIndex(index) {
            // Position the thumb / dragger at a predefined index.

            const d = this.getDragger();
            let x = this.getIndexToPosX(index, d.minX, d.maxX);

            Logger.log("positionAtIndex", index, x, d.minX, d.maxX);
            this.positionAtX(x);
        },

        getPosToX( p, minX, maxX ) {
            // Get x value of decimal position, p

            const w = maxX-minX;

            let steps = this.steps;
            let one_step_val = (maxX / steps);
            let range = maxX - one_step_val;
            let res = (one_step_val*.5) + (this.thumb_dim/2) + Math.round(p * range); //  +

            if(!this.is_snap) {
            // Edge case to fix resize for fluid slider.
            res = minX + (p * w);
            }

            Logger.log("Slider.getPosToX", p.toFixed(2), {res,steps,one_step_val,minX,maxX});

            return res;
        },

        getIndexToPosX( index, minX, maxX ) {

            let p = index / (this.steps-1); // make percentile relavent to total steps

            const w = maxX-minX;

            let one_step_val = (maxX / (this.steps));
            let range = maxX - one_step_val;

            let res = (one_step_val*.5) + (this.thumb_dim/2) + Math.round(p * range); //  +

            Logger.log("getIndexToPosX", index);

            return res;
        },

        getNearestIndex( x, minX, maxX ) {
            // Work out index, and use that to make the pos from, based on index/this.steps
            x -= this.thumb_dim/2; // offset to be centre of thumb.
            const w = maxX - minX;
            let one_step_val = (maxX / (this.steps));
            return Math.ceil(x/one_step_val) - 1;
        },

        getSnap( x, minX, maxX ) {
            // Calculate the nearest snap, point based on value(x)
            x = Math.min( Math.max(minX, x), maxX ); // limit to the X range of the bounds/track

            let index = this.getNearestIndex(x, minX, maxX);
            const res = this.getIndexToPosX(index, minX, maxX);

            // Logger.log("getSnap", index, x.toFixed(2), res, maxX);
            return res;
        },//

        thumbTweenTo( x, dur ) {

            let draggable = this.getDragger();
            let slider = this;
            dur = isNaN(dur) ? .2 : dur;
            let ease = this.is_snap ? "bounce.out" : "sine.out";
            this.tweening = true;

            gsap.to(this.$refs.sliderThumb, {duration:dur, x:x, ease:ease,
                onUpdate: function() { draggable.update(); slider.updated(); },
                onComplete: function() { 
                    slider.tweening = false;
                    slider.updated(true);
                 }
            });

        },

        debugPos( pos ) {
            if(this.debug) {
                pos = pos ? pos : this.getPos();
                this.$refs.debugInput.value = "index:" + pos.index + ", " + pos.p.toFixed(3) + "%, intVal:" + pos.intVal +", x:" + pos.x.toFixed(2);
            }
        },

        initSliderInteractivity() {
            let sel = "#" + this.id + " .thumb";
            let thumb = this.$refs.sliderThumb;
            let track = this.$refs.sliderTrack;
            // Logger.log(this.$refs.sliderThumb, this.$refs);

            let slider = this;

            Logger.log("initSliderInteractivity", sel, track);

            // Init gsap draggable plugin for our thumb/track combo
            Draggable.create(sel, {
                type:"x",
                bounds: track,
                /*trigger: track,*/
                inertia: false,
                /*liveSnap:function(val) {return slider.getSnap(val, this.minX, this.maxX);},*/ // uncomment to not be fluid, snap on drag
                onClick: function() {
                    //Logger.log("slider thumb click", this);
                    if(slider.enabled) {
                        slider.dragging = true; 
                    }
                },
                onDragStart: function() {
                    //Logger.log("Slider onStartDrag");
                    if(slider.enabled) {
                        slider.dragging = true;
                        slider.updated();
                    }
                },
                onDrag: function() {
                    //Logger.log("Slider onDrag");
                    if(slider.enabled) {
                        slider.dragging = true;
                        slider.updated();
                    }
                },
                onDragEnd: function() {
                    if(slider.enabled) {
                        slider.updated(true);
                        slider.dragging = false;
                        if(slider.is_snap){
                            var snapX = slider.getSnap( this.x, this.minX, this.maxX);
                            slider.thumbTweenTo(snapX);
                        }
                        //Logger.log("Slider onDragEnd");
                    }
                }
            });
            
            this.initialPosition();

        },

        initialPosition() {

            if(this.is_snap) {
                this.positionAtIndex( Math.floor(this.steps/2) ); // position in middle to start with.
            }else{
                this.positionAtDecimal(0.5);
            }

            this.debugPos();

        }
    }
}
</script>

<style lang="scss"  >

    @import "../vars.scss";

    /*$debug : false;*/
    .pv_slider {
        opacity:.2;
        pointer-events: none;

        &.enabled {
            opacity:1;
            pointer-events: inherit;
        }
        
        @if $debug{border:1px dashed white;}

        width:100%;

        .txt{
            position:relative;
            .lab {
                font-weight:700;
            }
            .val {
                position:absolute;
                top:0px;
                right:0px;
            }

        }

        $widg_h:40px;
        $track_h:2px;
        $thumb_h:20px;
        
        .widg {

            width:100%;
            height:$widg_h;

            @if $debug{border:1px dashed pink;}
            position:relative;

            .track {
                /*border:1px dashed pink;*/
                height:$widg_h;

                .fill, .bg{
                    width:100%;
                    height:2px;
                    display:block;
                    position:absolute;
                    left:0;
                    top:($widg_h/2) - ($track_h/2);
                }
                .bg {
                    background-color:$mid-grey;
                }
                .fill {
                    background-color:$acid-yellow;
                    width:0%;
                }

            }
            
        }

        .thumb {
            width:$thumb_h;
            height:$thumb_h;
            position:absolute;
            top:($widg_h/2) - ($thumb_h/2);;
            left:0;
            display:block;
            /*background:$acid-yellow;*/
            img {
                /*-webkit-user-drag: true !important;*/
            }
        }

        .debug{
            /*position:fixed; top:0; left:0; */
            font-size:.8em; 
            width:100%; 
            color:white; 
            background-color:rgba(0,0,0,.66)
        }
    }

</style>