<template>
  <div id="app" :class="{embedded:is_embedded}">

      <!-- Blocking loader bg -->
    <transition name="fade" mode="out-in">
      <div :class="[{'is-active': loading_data}, 'modal']" >
          <div class="modal-background"></div>
          <div class="modal-content">
            <img class="spinner" src="./assets/loading.svg" />
          </div>
      </div>
    </transition>

    <div v-if="can_show_nav" ref="navbar" class="navbar trans-bg" >

      <div class="main columns is-vcentered is-marginless is-mobile" >

        <div class="column site-logo but is-narrow">
          <a @click="scrTo('home')" > 
            <img class="virus_icn" src="./assets/virus_icon.png" />
            <img class="logo_type" src="./assets/logo-type.svg" />
          </a>
        </div>

        <div class="column spacer" ></div>

        <div class="column is-narrow is-paddingless">

          <div class="share columns is-mobile is-marginless">

            <div class="column but twitter has-text-centered" >
                <a rel='noreferrer' href='https://twitter.com/intent/tweet?text=New%20Lowy%20Institute%20research%20models%20the%20rollout%20of%20vaccines%20across%20the%20Pacific%20using%20a%20suite%20of%20country-specific%20variables.%20It%20reveals%20a%20Pacific%20divided%20by%20vaccine%20uptake.%20Explore%20at%20https%3A%2F%2Fpacificvaccination.lowyinstitute.org%20%23PacificVaccination%20%40LowyInstitute' target='_blank' >
                  <img src="./assets/twitter.svg" />
                </a>
            </div>

            <div class="column but facebook has-text-centered"> 
              <a rel='noreferrer' href='https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpacificvaccination.lowyinstitute.org' target='_blank' >
                <img src="./assets/facebook.svg" />
              </a>
            </div>

            <div class="column but email has-text-centered">
              <a href="mailto:?subject=Vaccinating%20the%20Pacific&amp;body=Check%20out%20the%20Lowy%20Institute%E2%80%99s%20Vaccinating%20the%20Pacific%20website.%20This%20Interactive%20provides%20country-specific%20modelling%20of%20Covid-19%20vaccination%20rates%20for%20each%20Pacific%20Island%20Country%2C%20using%20data%20available%20to%20October%202021.%20The%20Interactive%20employs%20a%20range%20of%20static%20and%20variable%20data%2C%20detailed%20in%20the%20Methodology%2C%20to%20generate%20for%20the%20first%20time%20a%20robust%20model%20for%20when%20each%20of%20the%20Pacific%20Island%20Country%20%20can%20expect%20to%20reach%20complete%20levels%20of%20full%20vaccination.%0A%20%0AWhat%20it%20shows%20is%20a%20divided%20Pacific.%20Size%20isn't%20everything%2C%20but%20when%20it%20comes%20to%20Covid-19%20vaccinations%2C%20being%20small%20often%20helps.%0A%20%0Ahttps%3A%2F%2Fpacificvaccination.lowyinstitute.org%0A">
                <img src="./assets/email.svg" />
              </a>
            </div>

            <div class="column but hamburger">
              <a @click="showMenu()">
                <img src="./assets/menu.svg" />
              </a>
            </div>

          </div>
        </div>
      </div>


    </div>

    <nav v-if="can_show_menu" class="menu h4">
      <div class="close">
        <a @click="closeMenu()">
          <img src="./assets/close.svg" />
        </a>
      </div>
      <div class="item">
        <a @click="menuNav('regional-timeline')">REGIONAL TIMELINE</a>
      </div>
      <div class="item">
        <a @click="menuNav('regional-overview')">REGIONAL OVERVIEW</a>
      </div>
      <div class="item">
        <a @click="menuNav('country-overview')">COUNTRY OVERVIEW</a>
      </div>
      <div class="item">
        <a @click="menuNav('vaccine-sources')">VACCINE SOURCES</a>
      </div>
      <div class="item">
        <a @click="menuNav('methodology')">METHODOLOGY</a>
      </div>
    </nav>
    
    <div class="main">

      <transition name="fade" mode="in-out" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <Home v-if="canAttach('home')" data-id="home" ref="home" @downArrowClicked="scrTo('regional-timeline')" v-show="inScrollView('home')" /> 
      </transition>

      <transition name="fade" mode="in-out" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <RegionalTimeline v-if="canAttach('regional_timeline')" data-id="regional_timeline" ref="regional_timeline" v-show="inScrollView('regional_timeline')" />
      </transition>

      <transition name="fade" mode="in-out" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <RegionalOverview v-if="canAttach('regional_overview')" data-id="regional_overview" ref="regional_overview" v-show="inScrollView('regional_overview')" />
      </transition>

      <transition name="fade" mode="in-out" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <CountryOverview  v-if="canAttach('country_overview')" data-id="country_overview" ref="country_overview" v-show="inScrollView('country_overview')" />
      </transition>

      <transition name="fade" mode="in-out" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <VaccineSources v-if="canAttach('vaccine_sources')" data-id="vaccine_sources" ref="vaccine_sources" v-show="inScrollView('vaccine_sources')" />
      </transition>

      <transition name="fade" mode="in-out" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <Methodology v-if="canAttach('methodology')" data-id="methodology" ref="methodology" v-show="inScrollView('methodology')" />
      </transition>

      <transition name="fade" mode="in-out" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <Credits v-if="canAttach('credits')" data-id="credits" ref="credits" v-show="inScrollView('credits')" />
      </transition>

    </div>

  </div>
</template>

<script>

import Home from './components/Home';
import VaccineSources from './components/VaccineSources';
import RegionalTimeline from './components/RegionalTimeline';
import RegionalOverview from './components/RegionalOverview';
import CountryOverview from './components/CountryOverview';
import Methodology from './components/Methodology';
import Credits from './components/Credits';

import Logger from './components/js/Logger';
import Consts from './components/js/Consts';
import Utils from './components/js/Utils';

import Model from './components/js/ForecastModel';

export default {
  name: 'App',
  components: {
    Home, VaccineSources, RegionalTimeline, RegionalOverview, CountryOverview, Methodology, Credits
  },
  data() {
        return { 
          comps: { // root component store
            "home":               {in_view:true,  bounds:null}, 
            "regional_timeline":  {in_view:false, bounds:null},
            "regional_overview":  {in_view:false, bounds:null}, 
            "country_overview":   {in_view:false, bounds:null},
            "vaccine_sources":    {in_view:false, bounds:null},
            "methodology":        {in_view:false, bounds:null},
            "credits":            {in_view:false, bounds:null}
          }
        }
  },
  computed: {
    loading_data() { 
      return this.$store.getters["loading_data"]; 
    },
    data_loaded() { 
      return this.$store.getters["data_loaded"]; 
    },
    can_show_menu() { 
      return this.data_loaded && !this.is_embedded;
    },
    can_show_nav() { 
      return this.data_loaded && !this.is_embedded;
    },
    is_embedded() {
        return this.$store.getters["is_embedded"];
    }
  },
  watch: { 
    data_loaded(new_val, old_val) { 
      Logger.log("App data_loaded changed", new_val);
      if(new_val == true) {
        // Do a scroll check so our things update correctly.
        let self = this;
        this.$nextTick(() => {
          self.checkScroll();
        });
      }
    }
  },
  created() {    
    Logger.enabled = Utils.getURLParameter("log", Logger.enabled);

    this.$store.commit("is_embedded", location.search.includes("regionalOverview"));

    this.$store.dispatch('fetchData');
    //Model.test();
   // Model.test2();
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.resize);
    window.addEventListener("orientationchange", this.onOrientationChange);

    if(this.is_embedded) {
        // tell system that regional_overview is in view and
        this.comps["regional_overview"].in_view = true;
    }

  },

  methods: {

    canAttach(id) {

      if(this.is_embedded) {
        if(id == 'regional_overview') {
          return this.data_loaded;
        }else{
          return false;
        }
      }

      return this.data_loaded;
    },

    inScrollView( ref_id ) {
      // return true;
      return this.data_loaded && this.comps[ref_id].in_view;
    },

    checkScroll(e) {
      // Check if any of our hero root doms are in scroll view and update the status

      let scroll_y = window["scrollY"] == undefined ? window["pageYOffset"] : window.scrollY;  // note: ie11 fix, might not be needed
      let win_height = window.innerHeight;

      //Logger.log("checkScroll", scroll_y, win_height);
      
      let i=-1;
      let prev_id = null;
      for(let id in this.comps) {
        i++;
        let ref = this.$refs[id];

        if(ref) {
          let el = ref.$el;
          let bnds = el.getBoundingClientRect();
          this.comps[id].bounds = bnds;
          let state = false;

          let exit = false;

          if(bnds.width == 0) {

            // item is not visible (display:none) so lets see if the previous element is at the end of the view and use that as the mark of visibility
            bnds = this.comps[prev_id].bounds;

            if(bnds) {
              let e_top = scroll_y + bnds.top;
              let e_bottom = e_top + bnds.height;
              //e_bottom += 10; // padd it a bit to make sure we get detection
              state = e_bottom >= (scroll_y); //);
              exit = state; // kill the loop as we have found an intial thing in nomands land, save all other components getting turned on.
            }

          }else{
            let e_top = scroll_y + bnds.top;
            // e_top -= 50; // add a pad so its detected a bit earilier
            let e_bottom = e_top + bnds.height;          
            state = (e_top < (scroll_y + win_height)) && (e_bottom > scroll_y); // it is now in view (or beyond...)
          }

          el.dataset.in_view = this.comps[id].in_view = state;
          // Logger.log("checkScroll", id, state, bnds, el);
          prev_id = id;

          if(exit) break;
        }

      }

      // Nav bar conditional transparency
      let nb = this.$refs.navbar;
      let cls = "trans-bg";
      if(nb) {
        //Logger.log("nb",nb);
        if(scroll_y > 80) {
          if(nb.classList.contains(cls)) {
            nb.classList.remove(cls);
          }
        }else{
          if(!nb.classList.contains(cls)) {
            nb.classList.add(cls);
          }
        }
      }

    },
    onScroll(e) {
      this.checkScroll();
    },
    resize(from_orientation) {
      this.checkScroll();
    },
    onOrientationChange(e) {
      //trace( "onOrientationChange" );
      var self = this; 
      setTimeout(function() {
        self.resize(true);
      }, 250); // slight delay so width and height are updated first
    },

    showMenu() {
        document.querySelector("nav.menu").classList.add("show");
    },
    closeMenu() {
        document.querySelector("nav.menu").classList.remove("show");
    },
    menuNav(anchor_name) {
      this.closeMenu();
      this.scrTo(anchor_name);
    },

    scrTo( anchor_name ) {
      // custom scroll code to over come menu offset issue.
      // window.location.hash = anchor_name;

      let el = document.querySelector("a[name=" + anchor_name + "]");
      
      if(el) {
        let dims = el.getBoundingClientRect();
        let scroll_y = window["scrollY"] == undefined ? window["pageYOffset"] : window.scrollY;  // note: ie11 fix, might not be needed
        let navHeight = document.querySelector(".navbar").getBoundingClientRect().height;
        Logger.log(el, dims, scroll_y, navHeight);
        let y = (scroll_y + dims.top) - navHeight;

        if(anchor_name == "home")  y = 0;

        window.scrollTo({
            left:0, 
            top:y,
            behavior: 'smooth'
        });
      }

    },

    // Custom transition methods
    beforeEnter(el) {
      Logger.log("Component::beforeEnter", el);
      //gsap.set(el, {autoAlpha:0});

      let id = el.dataset.id;
      let comp = this.$refs[id];
      if(comp) {
        Logger.log(comp);
        comp.build();
      }

    },
    enter(el, done) {
      // Logger.log("Component::enter", el);
      //done();
    },
    leave(el, done) {
      //Logger.log("Component::leave", el, done);      
      //done();
    }

  }

}
</script>

<style lang="scss">

@import "typography";
@import "vars";

/*$debug :true;*/

@import "~bulma/bulma";

/*
@import "~bulma/sass/utilities/_all";
@import "~bulma/sass/base/_all";

@import "~bulma/sass/elements/_all";
@import "~bulma/sass/form/_all";

@import "~bulma/sass/components/_all";

@import "~bulma/sass/grid/_all";
*/
/*@import "bulma/sass/grid/columns.sass";*/
/*
@import "~bulma/sass/helpers/_all";
@import "~bulma/sass/layout/_all";
*/

html, body {
  background-color:$background; /* Note: this is overriden in the static html.body file as inline style, so its not white on slow loading */
  scroll-behavior: smooth;
  font-size: 16px;
  @include mobile {
    font-size:14px;
    /*overflow-x:hidden;*/
  }
}

a, a:visited {
  color:$acid-yellow;
}
a:hover {
  color:white;
}

#app {
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: white;
}

svg {
  overflow:visible;
  text {
    user-select: none;
  }
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.hide-cursor { cursor: none; }
.ignore-clicks { pointer-events: none; }

img {
  -webkit-user-drag: none;
}

/* Loader */
.modal-content {
  text-align: center;
  .spinner {
    width:200px;
  }
}

.navbar {

  /*> .columns {
    height:22px;
  }*/

  width:100%;

  transition: background-color .5s;
  &.trans-bg {
    background-color:transparent;  
  }
  background-color:rgba($background,0.95);
  position:fixed;
  top:0px;
  left:0px;
  padding:0.5rem 1.0rem 0.25rem;

  .main {
    width:100%;
  }
  
  .columns {
    @if $debug { border:1px dashed yellow}
  }

  .column {
    @if $debug { border:1px dashed cyan}
    padding-top:0;
    padding-bottom:0;

    &.spacer {
      /*@include mobile() {
        display: none;
      }*/
    }

  }
  .site-logo {
    padding:0 0;
    img {
      height:24px; 
    }
    .logo_type {
      @include mobile() {
        display:none;
      }
    }
  }
  .share {
    /*background-color:blue;*/
  }

  .hamburger {
    /*position: fixed;
    top:1.0em;
    right:1.0em;*/
  }

  .but {
    width:24px;

    @if $debug {border:1px dashed red;}

    padding:0 0;
    margin-left:2.0em;

    &:first-of-type { /* the site logo */
      margin-left:0;
      width:inherit;
    }

    @include mobile {
      margin-top:0em;
    }

    img {
      /*border:1px dashed cyan;*/
      &.virus_icn {
        padding-right:.5em;
      }
      height:24px;
    }  
    
  }

}

.menu {
  text-align: left;
  position:fixed;
  right:0px;
  top:0px;
  z-index: 100;

  display:none;
  &.show {
    display: block;
  }

  width: 300px;
  height:100%;
  @include mobile {
    width:100%;
    font-size:1.8em;
  }

  background-color: rgba($dark-col,1.0);

  .close {
    width: 24px;
    position:fixed;
    top:1.0em;
    right:1.0em;
    @if $debug { border:1px dashed red; }
  }

  .item {
    
    &:first-of-type, &:nth-child(2){
      margin-top:2.5em;
      @if $debug { border:1px solid pink; };
    }

    /*border:1px solid pink;*/
    padding:0.5em 0.5em 0.5em 1.5em;

    @if $debug { border:1px dashed cyan; }

    a {
      color:white;
      display:inline-block;

      /* Animated underline effect */
      &:after {
        content: "";
        display: block;
        width: 0;
        margin-top:0.2em;
        height: 4px;
        background: $acid-yellow;
        transition: width .3s;
      }

      &:hover {
        text-decoration: none; 
        cursor:pointer;
        &:after {
          width: 100%;
        }
      }

    }
    
  }

}

.embedded {
  section {
    margin: 1.0em auto;
  }
}

section {
  @if $debug { border:1px dashed green; } 
  
  /*min-height:100vh;*/
  margin:4.0em auto 8.0em;

  /*@include mobile {
    overflow-x:hidden;
  }*/
}

p {
  margin:1.0em 0em;
}

ul {
  @include h3();
  
  list-style:outside !important; 
  margin-left:1.0em;

}


.main {

  @if $debug { border:1px dashed red; }
  
  .text-col{
    max-width:960px;
    margin:0 auto;
  }

  .inner-col {
    padding:0 1.5em;
    /*border: 1px dashed pink;*/
  }
}

.fade-enter-active {
    transition: opacity 1s ease-in-out .2s; /** last arg is delay */
}
.fade-leave-active {
    transition: opacity .1s ease-in-out 0s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}


</style>
