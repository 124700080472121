/* 
    Global project constants.

    see: https://coderethinked.com/3-different-ways-to-access-constants-in-a-vue-template/

    In template accessible by:
        $const('Intro')

    In .js:
        import Consts from "@/components/js/consts"
        var start = Consts.Intro;
*/

export default {

    START_DATE: new Date(2020, 12, 1),
    MAX_TIMELINE_DATE: new Date(2026, 12, 1), 
    MAX_REGIONAL_OVERVIEW_DATE: new Date(2023, 0, 1),
    MAX_COUNTRY_OVERVIEW_DATE: new Date(2027,0,1),

    USE_MODEL: true,
  
    color_list : [  "#00d5b0",
                    "#00ffff", 
                    "#00ffc2",
                    "#11c7b9", 		
                    "#00e1ff",			
                    "#1dc1e2", 
                    "#00b9a3", 	
                    "#6fb5bb", 				
                    "#45b1ba",		
                    "#00a8c3", 	
                    "#009a90",
                    "#0096be", 	
                    "#008a97", 				
                    "#007896"],

    // Source (Countries/Entities)
    AUSTRALIA:"au",
    USA:"us", 
    JAPAN:"jp",
    CHINA:"cn",
    COVAX:"cv",
    OTHER:"ot",

    // Recipient country ids
    COOK_ISLANDS: "ck",
    FIJI:"fj",
    KIRIBATI:"ki",
    MARSHALL_ISLANDS:"mh",
    MICRONESIA:"fm",
    NAURU:"nr",
    NIUE:"nu", 
    PALAU:"pw", 
    PNG:"pg", 
    SAMOA:"ws", 
    SOLOMONS:"sb",
    TONGA:"to",
    TUVALU:"tv",
    VANUATU:"vu",

    // Country Info panels
    ACCEPTANCE_RATE: "acceptance_rate",
    POPULATION: "population",
    SOURCES: "sources",
    URBANISATION: "urbanisation",
    VACCINATORS: "vaccinators",

    FIRST_VACC: 0,
    SECOND_VACC: 1, 

    // Population ids (keys in data packets as well)
    POP_18_PLUS: "ep",
    POP_12_PLUS: "tp",
    POP_TOTAL:   "tot",

};