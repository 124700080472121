/*
    VaccinationGraph
    Primary d3 based s curve component
*/

<template>
    <div :id="id" class='vac-graph'>

        <div class="graph-wrapper">
            
            <div class="pop-switcher graph-lab">
                <div class="tit graph-lab">POPULATION</div>
                <a v-for="(d, i) in get_pop_data_views" :data-id="i" :key="d.id" @click="(e) => popViewChange(i,e)"
                    :class="[(get_pop_view_index==i ? 'selected' : '' ),'but']" >
                    {{d.label}}
                </a>
            </div>

            <svg :class="svg_sel" width="100%" height="640px"
                xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"  xmlns:xlink="http://www.w3.org/1999/xlink"  >

                <rect class="bg" width="100%" height="100%" fill="cyan" fill-opacity="0.0" stroke="red" stroke-width="0"/>
                <!--<circle cx="400" cy="200" r="30" fill="red"/>-->

                <g class="grid"></g>
                <g class="graph_targ"></g>
                <g class="cur_proj">
                    <text class="c-name-tgl" x="-8" text-anchor="end" >CURRENT</text>
                    <text class="c-name-tgl" x="8" >PROJECTED</text>
                    <line class="cp-line" stroke="#c8ced1" stroke-dasharray="3 2" y2="100" />
                </g>

            </svg>

            <div v-if="show_recipients_legend" class="recipients-legend columns is-multiline is-marginless is-desktop" >
                <div class="column is-narrow is-paddingless ">                    
                    <div class="tit graph-lab">COUNTRY</div>
                </div>
                <div class="column countries">
                    <div class="columns is-multiline is-mobile">                    
                        <div v-for="(d, i) in countries" :data-id="d.code" :data-index="i" :key="d.code" :class="['but','c-name-tgl','column', 'is-narrow']" >
                            <div :class="['bg', 'bgcol'+(i+1)]"></div>
                            <a @click="recipientLegendItemClick(i)" @mouseover="recipientLegendItemOver(i)" @mouseleave="recipientLegendItemLeave(i)">
                                {{d.name}}
                            </a>
                        </div>
                    </div>  
                </div>
            </div> 

            <div v-if="show_dose_legend" class="dose-legend c-name-tgl">
                <div class="columns is-mobile is-vcentered is-marginless">
                    <div class="dose a column is-narrow is-vcentered">
                        1st dose 
                        <div class="line" :style='{backgroundColor:get_dose_first_col}' ></div>
                    </div>
                    <div class="dose b column is-narrow is-vcentered">
                        2nd dose 
                        <div class="line" :style='{backgroundColor:get_dose_second_col}' ></div>
                    </div>
                </div>
            </div>

            <transition name="tipfade" mode="out-in" >
                <CountryToolTip v-show="is_tooltip_visible" class="a" :code="active_country_code" 
                    :title="country_name" :date="get_date" :pop="get_tip_pop" :amount="get_dose" 
                    :x="get_tip_x" :y="get_tip_y" :bgcol="get_tip_bg" 
                    :pop_label="get_tip_pop_label" :lite_version="is_country_overview" />
            </transition>

        </div>

    </div>
</template>

<script>

import * as d3 from 'd3';
import Logger from './js/Logger.js';
import Utils from './js/Utils.js';
import Consts from './js/Consts.js';
import CountryToolTip from './CountryToolTip';
import Model from './js/ForecastModel';
import gsap from 'gsap';

export default {
    name:'VaccinationGraph',
    props: {
        country_overview:    {type: Boolean, default:false},
    },
    data() {
        return { 
            resize_timer_id: null,

            graph: {
                svg_dims: {},
                margin:{}, // padding around the graph
                rect: {}, // The rectangle of the graph area
                scales: {}, // Common used stuff
                svg_sel: "vac_graph_svg",
                colors: [],
                getX: null,
                getY: null
            },

            pop_data_views: [
                {index:0, label:"18+", tip_label:"of population >18", id:Consts.POP_18_PLUS},
                {index:1, label:"12+", tip_label:"of population >12", id:Consts.POP_12_PLUS},
                {index:2, label:"TOTAL", tip_label:"of total populuation", id:Consts.POP_TOTAL},
            ],

            pop_view_index: 0, 

            last_graph_update_ms:0,

            highlight_country_index: -1, // what country is rolled over
            highlight_point_data: null,

            graph_initialised: false,

            tooltip_visible: false,

            line_data:[], // transformed data used by d3 for graph
          
        }
    }, 
    components: {
        CountryToolTip
    },
    computed: {
        get_pop_view_index() {
            return this.pop_view_index;
        },
        get_pop_data_views() {
            return this.pop_data_views;
        },
        get_pop_id() {
            return this.pop_data_views[this.pop_view_index]["id"];
        },
        svg_sel() {
            return this.graph.svg_sel;
        },
        is_country_overview() {
            return this.country_overview;
        },
        show_recipients_legend() {
            return !this.is_country_overview;
        },
        show_dose_legend() { // 1st Dose, 2nd Dose key at bottom
            return this.is_country_overview;
        },
        get_selected_overview_county_index() {
            return this.$store.getters["selected_country_overview_index"];
        },
        get_selected_overview_county_data() {
            let sel_country_index = this.get_selected_overview_county_index;
            return (sel_country_index == -1) ? {} : this.countries[sel_country_index];
        },
        id() { 
            return this.$id('gph'); // uuid
        },
        get_svg_selector() {
            return "#" + this.id + " ." + this.graph.svg_sel;
        },
        countries() {
            return this.$store.getters["recipient_countries"];
        },

        is_tooltip_visible() {
            return this.tooltip_visible; // true
        },
        active_country_code() {
            return this.getCountryData(this.get_highlight_country_index, "code", Consts.SOLOMONS);
        },
        country_name() {
            return this.getCountryData(this.get_highlight_country_index, "name", "");
        },
        get_date() {
            if(this.get_highlight_country_index < 0) return "";

            let dt;

            if(this.highlight_point_data) { // get the date of the rollover / selected point this.is_country_overview && 
                dt = this.highlight_point_data["dt"];
            }else{
                
                // Get date of when the country will get to 100%    
                // Add this to incoming data.
                let mx = this.get_highlight_country_max_precalc; //[this.get_pop_id];
                dt = mx["dt"];
                // dt = this.countries[this.get_highlight_country_index]["timeline"]["date"] 
            }

            return Utils.dateToMYYYY( dt );
        },
        get_tip_pop() {
            if(this.get_highlight_country_index < 0) return "0%";

            let p;
            if(this.highlight_point_data) { // this.is_country_overview && 
                p = this.highlight_point_data.pop;
            }else{
                p = this.countries[this.get_highlight_country_index]["timeline"]["pop"];
            }
            return p + "%";
            //return Math.round(p) + "%"; // .toFixed(1
        },
        get_dose() {
            if(this.get_highlight_country_index < 0) return "0";


            let dose = 0;
            let pop_id = this.get_pop_id;


            let final_p = this.countries[this.get_highlight_country_index]["timeline"]["pop"] / 100;

            // let country_total_doses = this.countries[this.get_highlight_country_index]["timeline"]["doses"];

            // Total of pop X 2 doses.
            let country_total_doses = this.countries[this.get_highlight_country_index]["model"]["static"]["population"]["total"][pop_id] * 2;
            
            if(final_p < 1.0) {
                // We need to modifiy total doses to take into acount this country not reaching 100% in our base timeline.
                // country_total_doses += (1.0-final_p) * country_total_doses;
            }


            if(this.highlight_point_data) { 
                // Get the doses at the rollover / selected point this.is_country_overview && 
               
                
                let dose_index = this.highlight_point_data["dose_index"];
               
                if(Utils.has(this.highlight_point_data, pop_id)) {

                    // dose = this.highlight_point_data[pop_id][dose_index];
                    
                    let p = this.highlight_point_data.pop;
                    dose = country_total_doses * (p/100);

                    if(dose_index == Consts.FIRST_VACC) {
                        dose *= .5; // First Dose so Half it
                    }

                    /*if(dose_index == Consts.SECOND_VACC) {
                        dose *= (country_total_doses/100);
                    }else{
                        dose *= ((country_total_doses/2)/100); // First Dose so half the total
                    }*/

                }else{
                    dose = 0;
                }

                Logger.log("get_dose", {pop_id, dose_index, dose, d:this.highlight_point_data});


            }else{

                dose = country_total_doses * (final_p);

            }

            return Utils.formatDosesAmount( dose );
        },
        get_tip_x() {
            //TODO: if(is_country_overview) the rollover date?
            // else Needs to be when country gets to 100 - use the x0 scale passing in this.countries[this.get_highlight_country_index]["timeline"]["date"]            
            if(this.highlight_point_data) { // this.is_country_overview && 
                return this.highlight_point_data.x;
            }
            let mx = this.get_highlight_country_max_precalc;
            let x = (mx && this.graph.getX) ? this.graph.getX( mx ) : 450;
            return isNaN(x) ? 450 : this.graph.rect.left+x;// 120; //look up end data pos let dt = ["dt"];
        },
        get_tip_y() {
            if(this.highlight_point_data) { // this.is_country_overview && 
                return this.highlight_point_data.y;
            }
            let mx = this.get_highlight_country_max_precalc;
            let y = (mx && this.graph.getY) ? this.graph.getY( mx, Consts.SECOND_VACC ) : 180;
            return isNaN(y) ? 180 : this.graph.rect.top+y;// 120; //look up end data pos let dt = ["dt"];
            // return 180;// 70;
        },
        get_tip_bg() {
            if(this.get_highlight_country_index < 0) return "#CCCCCC";

            let dose_index = Consts.SECOND_VACC;
            if(this.highlight_point_data) { // this.is_country_overview && 
                dose_index = this.highlight_point_data.dose_index;
            }
            
            return this.getCountryColor(this.get_highlight_country_index, dose_index);
        },
        get_dose_first_col(){
            return this.getDoseColour(Consts.FIRST_VACC);
        },
        get_dose_second_col(){
            return this.getDoseColour(Consts.SECOND_VACC);
        },
        get_highlight_country_index() {
            if(this.is_country_overview) {
                return this.get_selected_overview_county_index;
            }else{
                return this.highlight_country_index;
            }
        },
        get_tip_pop_label() {
            return this.pop_data_views[this.pop_view_index].tip_label;
        },
        get_vaccinator_slider_value() {
            return this.$store.getters["slider_vaccincators_count"];
        },
        get_acceptance_slider_value() {
            return this.$store.getters["slider_acceptance_rate"];
        },
        get_highlight_country_max_precalc() {
            if(this.get_highlight_country_index < 0) return;
            return this.countries[this.get_highlight_country_index]["max_precalc"][this.get_pop_id];
        }
    },  

    watch: {

        get_selected_overview_county_index(new_val, old_val) {
            Logger.log("watch::get_selected_overview_county_index", {new_val, old_val})
            if(this.is_country_overview) { 
                this.updateSliderStores();
                this.updateGraph(true, true);            
            }
        },

        get_vaccinator_slider_value(new_val, old_val) {
            if(this.is_country_overview) { 
                this.updateGraph(true, false);
            }
        },

        get_acceptance_slider_value(new_val, old_val) {
            if(this.is_country_overview) { 
                this.updateGraph(true, false);
            }
        },

    },

    created() {        
        this.graph.colors = d3.scaleOrdinal().range( Consts.color_list.slice() );
        // TODO: could initialise a lot of base stuff here that does not need re-processing every time the graph is updated.
    },

    mounted() {
        Logger.log("VacGraph Mounted", this); 
        window.addEventListener('resize', this.windowResize);
        if(this.is_country_overview) { 
            this.updateSliderStores();
        }
        this.updateGraph(false, true); // DEV for now draw it
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.windowResize);
    },

    methods: {

        updateSliderStores() {
            let m = this.countries[this.get_selected_overview_county_index]["model"];                
            this.$store.commit( "slider_vaccincators_count", m["static"]["workforce"]["vaccinators"] );
            this.$store.commit( "slider_max_vaccincator_count", m["static"]["workforce"]["max_vaccinators"] );
            this.$store.commit( "slider_acceptance_rate", m["static"]["speed"]["acceptance"] ); 
        },
        
        getCountryColor(i, dose_index) {
            let c = this.graph.colors( this.countries[i]["code"] );
            if(dose_index == 0) {
                c = gsap.utils.interpolate(c, "#242a36", 0.33); // Mix this with bg colour to give us the dark effect
            }
            return c;
        },

        getDoseColour( vac_index ) { // For country overview graph
            let a = 1; //(vac_index == Consts.FIRST_VACC) ? .5 : 1;
            let hex = "#CCCCCC";
            if(this.get_selected_overview_county_index >= 0) {
                hex = this.getCountryColor(this.get_selected_overview_county_index, vac_index);
            }
            return hex;
        },

        getCountryData(country_index, key, def) { //helper

            def = (def == undefined) ? "" : def;
            if(country_index < 0) return def;
            
            let dat = this.countries[country_index];

            return (dat && Utils.has(dat, key)) ? dat[key] : def;
        },

        popViewChange( index, event ) {
            Logger.log("popViewChange", index, event);

            let alt = (event.altKey == true);

            // if(index == this.pop_view_index) return;
            this.pop_view_index = index;

            this.updateGraph(true, true, alt);
        },

        getPopDataView( index ){
            if(isNaN(index)) index = this.pop_view_index;
            return  this.pop_data_views[index];
        },

        windowResize(e) {
            if(this.resize_timer_id !== null) clearTimeout(this.resize_timer_id);
            this.resize_timer_id = setTimeout( this.resize, 300);  // launch a timeout to call resize
        },

        resize() {
            this.resize_timer_id = null;
            this.updateGraph(true, false);
        },

        setSvgDims( w, h, ignore_footer ) {

            let sw = isNaN(w) ? window.innerWidth : w;
            let sh = isNaN(h) ? window.innerHeight : h;

            let div_bounds = document.getElementById( this.id ).getBoundingClientRect();
            Logger.log("div_bounds", this.country_overview, div_bounds);

            let footer_h = 0; // TODO: read the footer height
            const rem_footer_h = 0; // (ignore_footer === true) ? 0 : footer_h;

            let gw = Math.min(sw, div_bounds.width); // Math.max(sw - div_bounds.left - 15, 320);
            
            // this.graph.svg_dims.height = Math.max(sh - (window.scrollY+div_bounds.top) - rem_footer_h, 240); // 120 == remove height of footer.

            let gh = div_bounds.height - rem_footer_h; // 120 == remove height of footer.

            // Dont let it go beyond square shape
            let max_ratio = 16/7;
            let ratio = gh / gw;

            Logger.log({gw,gh,max_ratio,ratio});

            if(ratio < max_ratio) {
                gh = gw / max_ratio;
            }

            // Height needs a minimum so the labels fits its
            gh = Math.max(gh, this.country_overview ? 520 : 520); // 680 is based on the yaxis vertical text label being visible.
            
            this.graph.svg_dims.width = gw;
            this.graph.svg_dims.height = gh;

            Logger.log("setSvgDimensions",this.graph.svg_dims.width, this.graph.svg_dims.height, gh );

            let svg = d3.select(this.get_svg_selector)
                .attr("width", this.graph.svg_dims.width)
                .attr("height", this.graph.svg_dims.height);

            this.graph.margin = {top:60, right:30, bottom:60, left:80};

            // Graph rect
            this.graph.rect= Utils.createRect(this.graph.margin.left, this.graph.margin.top, this.graph.svg_dims.width-(this.graph.margin.right+this.graph.margin.left), this.graph.svg_dims.height-(this.graph.margin.bottom+this.graph.margin.top) );
        },

        clearGrid() {
            return d3.select(this.get_svg_selector)
                .select("g.grid")
                .selectAll("g.grid_group")
                .remove();  // remove the grid group first.
        },

        updateGraph( rebuild, build_lines, toggle ) {

            let now_ms = Utils.getElapsedMilliseconds();

            if(now_ms - this.last_graph_update_ms < 200 ) {
                return; // not enough time has elapsed to call this again
            }

            this.last_graph_update_ms = now_ms;

            // initCommon
            const self = this; // ref for closures
            let svg = this.setSvgDims();

            svg = d3.select(this.get_svg_selector);
            
            if(rebuild === true) {
                svg.selectAll("g.graph_root").remove();
            }

            build_lines = (build_lines === true);

            const graph_g = svg.select("g.graph_targ");
            var root_g = graph_g.selectAll("g.graph_root")
                .data([0]).enter().append("g").classed("graph_root", true) // TODO: This line seems wierd needing the data call?
                .attr("transform", `translate(${this.graph.margin.left},${this.graph.margin.top})`)
            // .attr("data-test", Math.random()); // check its updating in dom

            var countries = this.$store.getters["recipient_countries"];
            var has_data = countries.length > 0;
           
            var country_ids = countries.map( function(value, index, arr) {
                return value["code"];
            })

            Logger.log("updateGraph", {rebuild, build_lines, toggle}, this.is_country_overview, this.highlight_country_index, this.graph.rect, countries.length, country_ids, this);

            // initBarLineCommon

            // Map access to the color range to that of the recipients as well.
            this.graph.colors.domain( country_ids );

            Logger.log("colors domain", this.graph.colors.domain());

            // Set up AXIS'
            // Scale to target the years (x points on graph)
            this.graph.scales.x0 = d3.scaleTime()
                .range([0, this.graph.rect.width]);
            
            // LHS Y axis
            this.graph.scales.y0 = d3.scaleLinear()
                .range([this.graph.rect.height, 0])
                .domain([0, 100]);

            // doBarLineGrid
            if(rebuild === true) {
                this.clearGrid();
            }
            
            // Intialise the line data (drawLineGraph)

            // const VIEW_ID = this.getPopDataView().id; // Key in our vacc_rates objects (ep, tp, tot)

            this.line_data = [];

            const REGIONAL_OVERVIEW_USE_MODEL = Consts.USE_MODEL && (toggle !== true);
            const CURVED_LINES = true;

            let start_date = Consts.START_DATE; // First date always at start of 2021
            let end_date = this.is_country_overview ? Consts.MAX_COUNTRY_OVERVIEW_DATE : Consts.MAX_REGIONAL_OVERVIEW_DATE; // New end date for the comparisons graphs  Consts.END_DATE;
 
            let max_date = end_date;

            var POP_ID = this.getPopDataView().id;

            const SHOW_MONTHS_AT = 12; // 1 for debug. 12 

            const modelData = function( cmodel, cdata, vaccinator_total, vaccine_acceptance ) {
                let dat = cdata.slice(); // shallow copy it
                let res = Model.solve( cmodel, 
                                vaccinator_total, 
                                vaccine_acceptance, // acceptance variable
                                POP_ID, 
                                end_date, // Max date to loop through
                                null ); //expected_data
                
                // Walk res converting it into our dated data format of 
                let c = res.length;
                for(let i = 0; i<c; i++) {
                    let packet = {
                        dt:res[i].dt
                    }
                    packet[POP_ID] = [ res[i].total_doses_1st_p[POP_ID], res[i].total_doses_2nd_p[POP_ID] ];
                    dat.push(packet);

                    /*{
                        dt:
                        ep: [total_doses_1st_p, total_doses_2nd_p]
                        tp: [total_doses_1st_p, total_doses_2nd_p]
                        tot: [total_doses_1st_p, total_doses_2nd_p]
                    }*/
                }

                return dat;
            }

            const isDataAt100Already = function( vacc_rates ) {

                let result = false;
                let c = vacc_rates.length;
                
                if(c) { // Check value of last entry to see if its at 100
                    let v = vacc_rates[c-1][POP_ID][Consts.SECOND_VACC];
                    result = (v) >= 100
                }

                return result;
            }

            var at100 = false; // is the data set already at 100?

            if(this.is_country_overview) {
                
                // Hero single selected country
                let c_index = this.get_selected_overview_county_index;
                let cdata = this.get_selected_overview_county_data;
                
                let data_key = Consts.USE_MODEL ? "vacc_rates" : "full_vacc_rates";  // use the cached data if not the model.

                // Lets work out if this goes to 100 already?
                at100 = isDataAt100Already( cdata[data_key] );

                var dat = null;
                
                if( !at100 && Consts.USE_MODEL ) {
                    // Use the Model to calculate the remaining dates
                    let cmodel = cdata["model"];
                    let vt = this.get_vaccinator_slider_value;     
                    let va = this.get_acceptance_slider_value; 
                    dat = modelData(cmodel, cdata[data_key], vt, va);
                    // Logger.log("dDat", dat);
                }else{
                    Logger.log("at100!", cdata.name);
                    dat = cdata[data_key];
                } 

                // do as 2 Seperate data streams, so we can end them at 100
                var dat_1st_dose = [];
                var dat_2nd_dose = [];
                var at100_1st = false;
                var at100_2nd = false;
                var over0_2nd = false;

                for(let i=0; i<dat.length; i++) {
                  
                    if(!at100_1st) {
                        
                        let v = dat[i][POP_ID][Consts.FIRST_VACC]

                        if(Math.round(v) >= 100) {
                            dat_1st_dose.push({...dat[i]});
                            at100_1st = true;
                        }else{
                            dat_1st_dose.push({...dat[i]});
                        }
                    }
                    
                    if(!at100_2nd) {
                        
                        let v = dat[i][POP_ID][Consts.SECOND_VACC];

                        //if(Math.round(v) >= 100) {
                        if(100 - v < .3){  // allow values of 99.7 to be == 100.
                            dat_2nd_dose.push({...dat[i]});
                            at100_2nd = true;
                        }else{
                            // dat_2nd_dose.push({...dat[i]});
                            
                            if(v != 0) {
                                // Dont add more than 1 2nd dose value a 0, so line head is not so long
                                if(!over0_2nd && i > 0) {
                                    dat_2nd_dose.push({...dat[i-1]});
                                    dat_2nd_dose.push({...dat[i]});
                                    over0_2nd = true;
                                }else{
                                    dat_2nd_dose.push({...dat[i]});
                                }
                            }
                        }

                    }

                }

                this.line_data.push(
                    {
                        code:cdata["code"],
                        index:c_index, 
                        dose:Consts.FIRST_VACC,
                        data:dat_1st_dose
                    },
                    {
                        code:cdata["code"], 
                        index:c_index, 
                        dose:Consts.SECOND_VACC,
                        data:dat_2nd_dose
                    },
                );

                // Calculate the max date of the data to specify out end axis

                max_date = start_date;
                // Walk all dates, looking for largest
                for(let i = 0; i < this.line_data.length; i++) {
                    for(let j = 0; j < this.line_data[i]["data"].length; j++) {
                        let test_date = this.line_data[i]["data"][j]["dt"];
                        if(test_date > max_date) {
                            max_date = test_date;
                        }
                    }
                }
                // Make the end date 1st jan of next year from the max_date
                Logger.log("spread data", max_date, dat_1st_dose, dat_2nd_dose);

            }else{ 
                // ALL COUNTRIES

                var data_key = REGIONAL_OVERVIEW_USE_MODEL ? "vacc_rates" : "full_vacc_rates";

                this.line_data = countries.map(function(cdata, index, arr) {
                    
                    at100 = isDataAt100Already( cdata[data_key] );

                    var dat = [];
                    var tmp;
                    
                    if( !at100 && REGIONAL_OVERVIEW_USE_MODEL ) {
                        // Use the Model to calculate the remaining dates
                        let cmodel = cdata["model"];
                        // Use base vaccinators and acceptance for the overview model
                        let vt = cmodel["static"]["workforce"]["vaccinators"];
                        let va = cmodel["static"]["speed"]["acceptance"]; 
                        tmp = modelData(cmodel, cdata[data_key], vt, va);
                    }else{
                        Logger.log("at100!", cdata.name);
                        tmp = cdata[data_key]; // All data pre-modeled. vacc_rates
                    }

                    // Only add data points before and the first at 100.
                    // And only one zero point
                    let c = tmp.length;
                    let over_0 = false;

                    for(let i=0; i<c; i++) {

                        let v = tmp[i][POP_ID][Consts.SECOND_VACC];
                        
                        // if((v) >= 100) { // Math.round(
                        if(100 - v < .8){  // allow 99.2 to be treated as 100!
                            dat.push(tmp[i]);
                            break;
                        }else{
                            if(v != 0) {
                                // Dont add more than 1 2nd dose value a 0, so the line head is not so flat/long
                                if(!over_0 && i > 0) {
                                    dat.push(tmp[i-1]); // add the previous one
                                    dat.push(tmp[i]);
                                    over_0 = true;
                                }else{
                                    dat.push(tmp[i]);
                                }
                            }
                        }
                    }
                    
                    return {code:cdata["code"], index:index, dose:Consts.SECOND_VACC, data:dat}; 
                });

            }

            if(this.is_country_overview) {
                
                // end_date = new Date(max_date.getFullYear()+1, 0, 1);
                end_date = new Date(max_date);
                Utils.addMonths(end_date, 1);

            }else {
                
                // Only if its less than the maximum we want to show
                if(max_date.getFullYear() <= end_date.getFullYear()) {    
                   // end_date = new Date(max_date.getFullYear()+1, 0, 1);
                }

            }
            
            var month_domain = [start_date, end_date];
            Logger.log("Date domain", this.is_country_overview, {month_domain});

            // this.graph.scales.x0.domain( [2021,2022,2023,2024,2025,2026,2027] );
            this.graph.scales.x0.domain( month_domain ); //.nice();
            Logger.log("x0 domain", this.graph.scales.x0.domain());
            
            svg = d3.select(this.get_svg_selector);

            // Create GRID
            const grid = svg.select("g.grid").append("g").classed("grid_group", true)
                .attr("transform", `translate(${this.graph.rect.x},${this.graph.rect.y})`)
            
            // Y Axis gridlines
            grid.append("g").classed("axis y-axis", true)
                .call( d3.axisLeft(this.graph.scales.y0)
                    .ticks( null, "s")
                    .tickSize( -this.graph.rect.width )
                    .tickFormat("")
                );

            // X Axis gridlines
            grid.append("g").classed("axis x-axis", true)
                .call( d3.axisBottom(this.graph.scales.x0)
                    //.ticks( null, "s")
                    .ticks( d3.timeMonth.every(SHOW_MONTHS_AT) )
                    .tickSize( this.graph.rect.height )
                    .tickFormat("")
                );

            // Logger.log({line_data});
            /* Data Packet Format:
                vacc_rates": [
                    {
                        "dt": "1/1/2021",
                        "tp": [0, 0],
                        "ep": [0, 0],
                        "tot": [0, 0]
                    }, 
                ]
                */

            // Do axis'
            // doBarLineAxis
            root_g.append("g")
                .classed("axis x-axis graph-num", true)
                .attr("transform", "translate(0," + this.graph.rect.height + ")")
                .call( d3.axisBottom(this.graph.scales.x0).ticks( d3.timeMonth.every(SHOW_MONTHS_AT) ) )
                .call(g => g.selectAll(".tick text").attr("dy", 22).attr("dx", 2))  // offset the labels

            root_g.append("g")
                .classed("axis y-axis graph-num", true)
                .call( d3.axisLeft(this.graph.scales.y0).ticks( null, "s") ) //.tickFormat( graph.moneyFormatter(true) ) );
                .call(g => g.selectAll(".tick text").attr("dx", -5))  // offset the labels

            var x_offset = 0; // this.graph.scales.x0.bandwidth() * .5;

            // Line XY function: uses the data and scales to plot the x,y
            this.graph.getX = function(d) {
                let r = self.graph.scales.x0( d["dt"] ) + x_offset;
                return r + x_offset;
            }

            this.graph.getY = function(d, dose_index) {
                // Logger.log("getY", d, dose_index)
                let v = Math.min(100, d[self.getPopDataView().id][dose_index]);
                let r = self.graph.scales.y0( v );
                return r;
            }

            // var initTransLine = d3.line().x( (d) => 0 ).y( (d) => self.graph.rect.height ); // where a line starts from when drawn on.

            const getMonthDataFromMouse = function(e, data_arr) {

                // Using the mouse_event x pos work out the month packet we need from data_array and return it.
            
                let month_domain = self.graph.scales.x0.domain();
                let months = Utils.monthDiff(month_domain[0], month_domain[1]) ;
                let gw = self.graph.rect.width;
                let one_month_w = (gw / months);

                let mx = Math.max(0, e.layerX - self.graph.rect.left); // Note this works, d3.pointer below has floating point, bit more accurate.

                let my = e.layerY - self.graph.rect.top;
                my = Math.max(0, self.graph.rect.height - my); // reverse it. so 0 is at bottom of screen
                
                let ypos = my / self.graph.rect.height;

                // let d3mouse = d3.pointer(mouse_event); // graph_g.selectAll("g.graph_root"));
                // mx = d3mouse[0];

                // Work out what month index data_arr stats at (it might not be 0)
                let data_month_len = data_arr.length;
                let data_start_month = data_arr[0].dt;
                let data_start_index = Utils.monthDiff(month_domain[0], data_start_month);

                let mp = (mx)/one_month_w;

                let a_indx =  Math.floor(mp) - data_start_index;
                let b_indx = Math.ceil(mp) - data_start_index;

                let month_a = data_arr[a_indx];
                let month_b = data_arr[b_indx];
                let pos = mp - Math.floor(mp); // the floating point (remainder)

                
                let da = Utils.dateToMYYYY(month_a.dt);
                let db = Utils.dateToMYYYY(month_b.dt);
                
                // one_month_w, months, data_month_len, data_start_index, gw, mx,

                Logger.log("getMonthDataFromMouse", { mp:mp.toFixed(3), my, ypos, p:pos.toFixed(3), da, db,  a_indx, b_indx, month_a, month_b}); //  , self.graph.rect

                return {
                    closest_month: pos > 0.5 ? month_b : month_a,
                    month_a,
                    month_b,
                    pos, // position in between dates
                    ypos
                };
            }
            
            var do_build = build_lines; // !this.country_overview;

            var pthz = root_g.append("g").classed("pathz", true);
            var roll_pths = root_g.append("g").classed("rollovers", true); // group to hold interactive trigger rollovers
            
            // Create a line for every country..
            this.line_data.map( (line_dat, ri) => {
                    // Logger.log("create line", ri, line_dat );
                    var dose_index = line_dat.dose;
                    let col = self.graph.colors( line_dat["code"] );
                    let alpha = .3;
                    let stroke_size = 4;
                    
                    if(this.is_country_overview) {
                        col = this.getCountryColor(this.get_selected_overview_county_index, dose_index);
                        alpha = 1.0; //(dose_index == Consts.FIRST_VACC) ? .5 : 1.0;
                        stroke_size = (dose_index == Consts.FIRST_VACC) ? 2 : stroke_size;
                    }

                    var country_indx = this.is_country_overview ? this.get_selected_overview_county_index : ri;

                    var trans = d3.transition().ease(d3.easeSinOut).delay( (d,i) => do_build ? ri*100 : 0 ).duration(do_build ? 700 : 0);

                    let pth = pthz.append("path")
                        .datum(line_dat["data"])
                        .classed("line", true)
                        .attr("data-index", ri )
                        .attr("fill", "none")
                        .attr("stroke", col)
                        .attr("stroke-width", stroke_size)
                        .attr("stroke-linejoin", "round")
                        .attr("stroke-linecap", "round")
                        .attr("d", (d,i) => {
                            if(CURVED_LINES) {
                                return d3.line().x( self.graph.getX ).y( (d) => self.graph.getY(d, dose_index) ).curve(d3.curveBasis)(d); // curveLinear curveMonotoneX curveBasis d3.curveCatmullRom.alpha(0.5)
                                // See https://github.com/d3/d3-shape/blob/main/README.md#curves for more curve options
                            }else{
                                return d3.line().x( self.graph.getX ).y( (d) => self.graph.getY(d, dose_index) )(d); // STRAIGHT NO CHASER
                            }
                        })
                        .attr("stroke-opacity", alpha)
                        
                    // Draw path on using stroke-dash array
                    const len = pth.node().getTotalLength();

                    pth.attr("stroke-dasharray", len + " " + len)
                        .attr("stroke-dashoffset", len)
                        .transition(trans)
                        .attr("stroke-dashoffset", 0);

                    // ADD ROLLOVER ELEMENTS, Wider transparent lines on top.

                    const lineOn = (e, d, click) => {
                        let indx = e.target.dataset.index;
                        //Logger.log("mouseover", indx, e.target);
                        d3.select(e.target).raise(); // move the hit to the front so it stays in event space
                        
                        let real_line = root_g.select(".pathz path[data-index='" + indx + "']");
                        // Logger.log("real_line", indx, real_line);
                        real_line.raise(); // Move the visual path to the front

                        self.showGraphTip(country_indx, getMonthDataFromMouse(e, d), dose_index, (click===true), e) 
                    }

                    const lineOff = (e,d) => {
                        let indx = e.target.dataset.index;
                        d3.select(e.target).lower(); // move back
                        
                        let real_line = root_g.select(".pathz path[data-index='" + indx + "']");
                        real_line.lower();

                        self.hideGraphTip(country_indx);
                    }

                    roll_pths.append("path")
                        .classed("line-rollover", true)
                        .attr("data-index", ri )
                        .datum(line_dat["data"])
                        .on("click", (e,d) => lineOn(e,d,true))
                        .on("mouseover", lineOn)
                        .on("mousemove", lineOn)
                        .on("mouseout", lineOff)
                        .attr("fill", "none")
                        .attr("stroke", col )
                        .attr("stroke-width", 18)
                        .attr("stroke-opacity", 0.0)
                       // .attr('pointer-events', 'visibleStroke') // allow mouse events                       
                        .attr("d", (d,i) => {
                            // Logger.log("rollover d", i, d);
                            // !NOTE: matching the above line function
                            if(CURVED_LINES) {
                                return d3.line().x( self.graph.getX ).y( (d) => self.graph.getY(d, dose_index) ).curve(d3.curveBasis)(d);                                     
                            }else{
                                return d3.line().x( self.graph.getX ).y( (d) => self.graph.getY(d, dose_index) )(d); // STRAIGHT NO CHASER
                            }
                        })

                }
            );
            
            // Do Axis labels
                
            let show_sub_y_label = !this.is_country_overview;

            let yx = show_sub_y_label ? -65 : -52;

            root_g.append("text") // The LHS Y axis label
                .attr("transform", `translate(${yx}, ${this.graph.rect.bottom-58}) rotate(-90)`)
                .classed("axis_label y-axis graph-lab", true)
                .text("POPULATION FULLY VACCINATED (%)");

            if(show_sub_y_label) {
                // Add second label.
                root_g.append("text") // The 2nd LHS Y axis label
                .attr("transform", `translate(${-48}, ${this.graph.rect.bottom-58}) rotate(-90)`)
                .classed("axis_label y-axis graph-lab2", true)
                .text("CUMULATIVE INJECTIONS (1ST + 2ND DOSES)");
            }

            root_g.append("text") // The X axis label
                .attr("transform", `translate(${-14}, ${this.graph.rect.bottom-6})`)
                .classed("axis_label graph-lab", true)
                .text("VACCINATION DATE");

            let show_cp_line = true;

            let cp_line_date =  new Date(this.$store.getters["data_updated_dt"]); 
            
            cp_line_date = Utils.addMonths(cp_line_date, 1); // Add a month to offset month to make more intuitive.

            if(Consts.USE_MODEL) {
                show_cp_line = !at100 || cp_line_date < month_domain[1]; // the point of the last date of line is?
            }

            if(show_cp_line) {
                // Position the CURRENT/PROJECTED line
                let cp_x = this.graph.rect.x + self.graph.getX( {dt:cp_line_date} ); // Point where our data was last updated.
                let cp_y_pad = 12;
                let cp_y = this.graph.rect.y - cp_y_pad;
                let cp_h = this.graph.rect.height + cp_y_pad * 1.5;
            
                let cp = svg.select(".cur_proj")
                    .classed("hidden", false)
                    .attr("transform", `translate(${cp_x}, ${cp_y})`);
                cp.select("line").attr("y2", cp_h ).attr("y1", -12);
            }else{
                // Hide the CURRENT/PROJECTED line
                svg.select(".cur_proj")
                    .classed("hidden", true)
            }

            if(this.country_overview && Consts.USE_MODEL) {
                this.$store.commit("selected_country_pop_view_complete", at100);
            }

            this.graph_initialised = true;

        },
        
        highlightCountry( index, lock, from_legend ) {
            Logger.log( "highlightCountry", index, lock, from_legend);
            this.highlight_country_index = index;

            // d3.select(this.get_svg_selector + " path.line:nth-of-type(" + (index + 1) + ")").attr("stroke-opacity",1);
            d3.select(this.get_svg_selector + " path.line[data-index='" + index + "']").attr("stroke-opacity",1);

            if(from_legend !== true) {
                document.querySelector(".recipients-legend .countries .but[data-index='" + index + "']").classList.add("active");
            }

            this.tooltip_visible = true;
        },

        unHilightCountry( index, unlock, from_legend ) {
            
            Logger.log("unHilightCountry", index, unlock, from_legend);

            //d3.select(this.get_svg_selector + " path.line:nth-of-type(" + (index + 1) + ")").attr("stroke-opacity",.3);
            d3.select(this.get_svg_selector + " path.line[data-index='" + index + "']").attr("stroke-opacity",.3);

            if(from_legend !== true) {
                // this.hideGraphTip(index);
                document.querySelector(".recipients-legend .countries .but[data-index='" + index + "']").classList.remove("active");
            }
        },

        showGraphTip(index, data, dose_index, click, event) {
            Logger.log("showGraphTip",{index, data, dose_index, event});
            
            if(click === true) {
                if(this.highlight_country_index == index) {
                    // already visible so hide it.
                    //this.hideGraphTip(index);
                    //return;
                }
            }

            this.highlight_country_index = index;

            let d = {...data.month_a}; // Utils.cloneObject(data);
            // 
            // Logger.log("heres d", d);

            const INTERPOLATE = true;
            var pop_id = this.get_pop_id;

            if(INTERPOLATE) {
                d.dose_index = Number(dose_index);                
                //let pop_a = data.month_a[pop_id][dose_index];
                //let pop_b = data.month_b[pop_id][dose_index];
                // d.pop = Math.min(100, pop_a + ((pop_b-pop_a)* data.pos));
                d.pop = Math.min(100, Math.round(data.ypos * 100)); // 

            }else{
                d.dose_index = Number(dose_index);
                d.pop = Math.min(100, d[pop_id][dose_index]);
            }
            //d.dt = new Date(d.dt); // convert back to date
            d.x = event.offsetX;
            d.y = event.offsetY;

            this.highlight_point_data = d;

            // Logger.log("heres d after", d);

            this.tooltip_visible = true;

            if(!this.is_country_overview) {
                this.highlightCountry(index, false, false);
            }

        }, 

        hideGraphTip(index) {
            //Logger.log("hideGraphTip");
            if(!this.is_country_overview) {
                this.unHilightCountry(index, false, false);
            }
            this.tooltip_visible = false;
           // this.highlight_country_index = -1;
        },

        recipientLegendItemOver(index) {
            this.highlight_point_data = null; // So it uses the 100% value
            this.highlightCountry(index, false, true);
        },
        recipientLegendItemClick(index) {
            this.highlight_point_data = null; // So it uses the 100% value
            this.highlightCountry(index, true, true);
        },
        recipientLegendItemLeave(index) {
            this.unHilightCountry(index, true, true);
            this.hideGraphTip(index);
        },
        
    }
}
</script>

<style lang="scss" >

    @import "../vars.scss";
    @import "~bulma/sass/utilities/_all.sass"; // To get at the mixins for responsiveness.

    .vac-graph  {
        /*border:1px solid $acid-yellow;*/
        
        .graph-wrapper {
            position:relative;

            svg {
                
                overflow:hidden; /* was inherit */

                .hidden {
                    display:none;    
                }

                path {
                    transition:stroke-opacity .3s;
                }

                text {
                    fill:$mid-grey;
                }

                .axis {
                    line, path {
                        stroke:$dark-col;/*red*/;
                    }
                }

                .cur_proj{
                    text {
                        fill: white;
                    }
                }
            }
        }

        .pop-switcher {

            position:absolute;
            right:0px;

            .tit {
                display: inline-block;
                color:$highlight-blue;
                padding-right:.5em;
                @include mobile {
                    padding-right:.15em;
                }
            }

            .but {
                display:inline-block;
                margin:0 .4em;
                padding:0.4em .6em;
                @include mobile {
                    padding:0.33em .5em;
                }
                background-color:$mid-grey;
                color: #fff;
                &.selected, &:hover, &:active {
                    background-color:$highlight-blue;
                    color:$dark-col;
                }
                &.selected {
                    cursor: default;
                }
            }

            .lab {
                display:inline-block;
                color:$highlight-blue
            }
        }

        .dose-legend {
            /* Aligned to the right at the bottom */
            text-transform: uppercase;
            
            .dose {
                /*border:1px dashed red;*/
                 &.a {
                    .line {
                        height:2px;
                        margin-bottom:0.15em;
                    }
                }
                .line {
                    display:inline-block;
                    /*border:1px dashed pink;*/
                    margin-bottom:0.125em;
                    height:4px;
                    width:32px; 
                    background-color:inherit;                
                }
            }

            .columns {
                justify-content:flex-end; /* righ align  */
            }
        }

        .recipients-legend {

            /*border:1px dashed red;*/
            /*
                Use the preset colours to make color classes
            */
            padding-left:65px; /* so it lines up with the x axis (VACCINATION DATE) label above. */
            @include mobile {
                padding-left:0px;
            }

            .tit {
                color:$highlight-blue;
                padding-top:0.3em;
                padding-bottom:0.66em;

                @include mobile {
                    padding-left:0.1em; /*65px;*/
                }
            }

            .countries {
                padding-left:1.5em;
                @include mobile {
                    padding-left:0.75em;
                }
            }

            .column {
                /*border:1px dashed cyan;*/
            }

            .but {
                min-width:136px;

                @include mobile {
                    min-width:100px;
                }

                height:1.4em;
                line-height:1.5em;

                /*border:1px dashed cyan;*/

                padding:0; /*0 0 .5em;*/
                margin:0 0.5em 0.3em 0;
                position:relative;
                text-transform: uppercase;

                .bg {
                    width:100%;
                    height:100%;
                    display: inline-block;
                    position:absolute;
                    left:0px;
                    bottom:0px;
                    height:3px;
                    transition: all .3s;                    
                }

                a{
                    color:white;
                    position:absolute;
                    top:0px;
                    left:.33em;
                    width:100%;
                    transition: all .3s;
                    
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &.active, &:hover { /*, &:focus, &:active*/
                    a {
                        color:$dark-col;
                    }
                    .bg {
                        height:100%;
                    }
                }
            }

        }

        .tipfade-enter-active {
            transition: opacity .2s ease-out 0s; /** last arg is delay */
        }
        .tipfade-leave-active {
            transition: opacity .2s ease-in .1s;
        }
        .tipfade-enter, .tipfade-leave-to {
            opacity: 0;
        }

    }
    
</style>