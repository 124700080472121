/*
    RegionalTimeline

*/
<template>

    <section class="reg-tl inner-col" >

        <a class="anchor" :name='anchor' />

        <div class="text-col" >
            <div class="h1">REGIONAL TIMELINE</div>
            <div class="copy" v-html="pvGetContent('regional_timeline')"></div>
        </div>

        <div class="tl">

            <div class="chart" >
                <svg :class="svg_sel" width="100%" height="350px" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" >
                    <!-- <rect width="100%" height="100%" stroke="red" fill="none"/> -->
                    <rect class="clickRect" width="100%" height="100%" stroke="none" fill="red" fill-opacity="0.0"/>
                    <g class="grid"></g>
                    <g class="graph_targ"></g>
                </svg>
            </div>

            <transition name="tipfade" mode="out-in" >
                <CountryToolTip v-show="is_tooltip_visible" 
                    :code="active_country_code" 
                    :title="country_name" 
                    :date="get_date" 
                    :pop="get_pop" 
                    :amount="get_dose" 
                    :x="get_tip_x" :y="get_tip_y" 
                    :bgcol="get_bg"
                    :centre_to_pos="true"
                    pop_label="adult population"
                    />
            </transition>

        </div>

    </section>

</template>

<script>

import * as d3 from 'd3';
import Logger from './js/Logger';
import Utils from './js/Utils';
import Consts from './js/Consts';
import CountryToolTip from './CountryToolTip';

export default {
    name:'RegionalTimeline',
    props: {
       anchor:  {type:String, default:'regional-timeline'},       
    },
    data() {
        return { 
       
            graph: {
                svg_dims: {},
                margin:{}, // padding around the graph
                rect: {}, // The rectangle of the graph area
                scales: {}, // Common used stuff
                svg_sel: "tl_svg",
                colors: [],
                origin:{x:0,y:0}
            },
            vertical: false,

            tooltip_visible: false,
            tooltip_cc: "", 
            tooltip_elem: null,

            sel_tl_data: null,
            last_tooltip_shown_time:0,

            rollover_origin_pos: {x:280, y:125},

            BASE_DONUT_RAD: 20 // when a donut is at smallest value     
        }
    },
    components: {
        CountryToolTip
    },
    computed: {
        svg_sel() {
            return this.graph.svg_sel;
        },
        get_svg_selector() {
            return ".tl ." + this.graph.svg_sel;
        },
        is_tooltip_visible() {
            return this.tooltip_visible;
        },
        active_country_code() {
            return this.getCountryData("code", Consts.SOLOMONS);
        },
        country_name() {
            return this.getCountryData("name", "");
        },
        get_date() {
            return Utils.dateToMYYYY( this.getCountryData("date", "") );
        },
        get_pop() {
            return Math.round(this.getCountryData("pop", "0")) + "%";
        },
        get_dose() {
            return Utils.formatDosesAmount(this.getCountryData("doses", "0"));
        },
        get_tip_x() {
            return this.rollover_origin_pos.x;
        },
        get_tip_y() {
            return this.rollover_origin_pos.y;
        },
        get_bg() {
            return this.getCountryData("col", "#CCCCCC");
        }
    },

    watch: {

    },

    created() {
        this.graph.colors = d3.scaleOrdinal().range( Consts.color_list.slice() );
    },

    mounted() {
        window.addEventListener('resize', this.windowResize);
       
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.windowResize);
    },

    methods: {

        build() {
            Logger.log("build", this.anchor);
            this.$nextTick( () => {
                this.updateGraph(true); // DEV for now draw it
            });
        },
        
        getCountryData( key, def ) {
            def = def == undefined ? "" : def;
            return this.sel_tl_data ? this.sel_tl_data[key] : def;
        },

        windowResize(e) {
            if(this.resize_timer_id !== null) clearTimeout(this.resize_timer_id);
            this.resize_timer_id = setTimeout( this.resize, 300);  // launch a timeout to call resize
        },

        resize() {
            this.resize_timer_id = null;

            if(this.tooltip_visible) this.hideGraphTip();

            this.updateGraph(true);
        },

        setSvgDims( w, h, ignore_footer ) {

            let sw = isNaN(w) ? window.innerWidth : w;
            let sh = isNaN(h) ? window.innerHeight : h;

            let div_bounds = document.querySelector(".tl").getBoundingClientRect();
            Logger.log("div_bounds", div_bounds);

            let footer_h = 0; // TODO: read the footer height
            const rem_footer_h = 0; // (ignore_footer === true) ? 0 : footer_h;

            let gw = Math.min(sw, div_bounds.width); // Math.max(sw - div_bounds.left - 15, 320);
            
            // this.graph.svg_dims.height = Math.max(sh - (window.scrollY+div_bounds.top) - rem_footer_h, 240); // 120 == remove height of footer.

            let gh = div_bounds.height - rem_footer_h; // 120 == remove height of footer.

            // dont let it go beyond square shape
            /*let max_ratio = 16/9;
            let ratio = gh / gw;

            Logger.log({gw,gh,max_ratio,ratio});

            if(ratio < max_ratio) {
                gh = gw / max_ratio;
            }

            // Height needs a minimum so the labels fits its
            gh = Math.max(gh, 680); // 680 is based on the yaxis vertical text label being visible.
            */
            gh = this.vertical ? 600 : 370;

            this.graph.svg_dims.width = gw;
            this.graph.svg_dims.height = gh;

            Logger.log("setSvgDimensions", this.graph.svg_dims.width, this.graph.svg_dims.height, gh );

            let svg = d3.select(this.get_svg_selector)
                .attr("width", this.graph.svg_dims.width)
                .attr("height", this.graph.svg_dims.height);

            this.graph.margin = {   top:this.vertical ? 20 : 0, 
                                    right:40, 
                                    bottom:this.vertical ?  20 : 170, 
                                    left:40};

            // Graph rect
            this.graph.rect= Utils.createRect(this.graph.margin.left, this.graph.margin.top, this.graph.svg_dims.width-(this.graph.margin.right+this.graph.margin.left), this.graph.svg_dims.height-(this.graph.margin.bottom+this.graph.margin.top) );
        },

        updateGraph: function(rebuild) {
            // initCommon
            const self = this; // ref for closures

            this.vertical = window.innerWidth < 600;

            let svg = this.setSvgDims();

            svg = d3.select(this.get_svg_selector);
            
            if(rebuild === true) {
                svg.selectAll("g.graph_root").remove();
            }

            const graph_g = svg.select("g.graph_targ");
            var root_g = graph_g.selectAll("g.graph_root")
                .data([0]) // TODO: This line seems wierd needing the data call?
                .enter().append("g").classed("graph_root", true) 
                .attr("transform", `translate(${this.graph.margin.left},${this.graph.margin.top})`)
           
            // svg = root_g;
            
            var countries = this.$store.getters["recipient_countries"];
            var has_data = countries.length > 0 && Utils.has(countries[0], "timeline");

            if(!has_data) return;
            
            /* var country_ids = countries.map( function(value, index, arr) {
                return value["code"];
            })*/

            // Timeline data structure of countries
            /*JSON: "timeline": {
                "pop": 100,
                "doses": 130223,
                "date": "2021-12-01"
            }*/
            var tl_data = [];
            for(let i=0; i<countries.length; i++) {
                tl_data.push(countries[i]["timeline"]);
                tl_data[i]["code"] = countries[i]["code"];
                tl_data[i]["name"] = countries[i]["name"];
                tl_data[i]["date"] = Utils.mkDate(tl_data[i]["date"]);
            }

            // Sort them based on date
            tl_data.sort((a, b) => a.date - b.date);

            const TwoPi = 2*Math.PI;

            // Set colour based on index.
            for(let i=0; i<tl_data.length; i++) {
                tl_data[i]["col"] = this.graph.colors(i);

                // Setup PIE arcs
                tl_data[i]["arcs"] = []; 

                let p = Number(tl_data[i].pop/100);
                if(p == 1) {
                    // 1 ring.
                    tl_data[i]["arcs"].push({index:i, col:tl_data[i]["col"], startAngle: 0, endAngle:TwoPi, opacity:1});
                }else{
                    // 2 arcs (sectors)
                    let a =  p * TwoPi;
                    tl_data[i]["arcs"].push({index:i, col:tl_data[i]["col"], startAngle: 0, endAngle:a, opacity:1}); // shows how much
                    tl_data[i]["arcs"].push({index:i, col:tl_data[i]["col"], startAngle: a, endAngle:TwoPi, opacity:.25}); // translucent rest
                }
            }

            // Sort countries based on doses so small ones are in front of the
            tl_data.sort((a, b) => b.doses - a.doses);

            Logger.log(this.get_svg_selector, "RegionalTimeline::updateGraph", this.graph.rect, countries.length, tl_data, this);

            // initBarLineCommon

            // Map access to the color range to that of the recipients as well.
            // this.graph.colors.domain( country_ids );
            // Logger.log("colors domain", this.graph.colors.domain());

            // Set up AXIS'
            // Scale to target the years (x points on graph)
            let end = this.vertical ? this.graph.rect.height : this.graph.rect.width;
            this.graph.scales.x0 = d3.scaleTime()
                .range([0, end])

            var month_domain = [
                Consts.START_DATE,              // first date
                Consts.MAX_TIMELINE_DATE,       // last date
            ];

            Logger.log({month_domain});

            // this.graph.scales.x0.domain( [2021,2022,2023,2024,2025,2026,2027] );
            this.graph.scales.x0.domain( month_domain );

            Logger.log("x0 domain", this.graph.scales.x0.domain());            

            const scaleAccessor = (d) => d.doses;
            this.graph.scales.size = d3.scaleSqrt()  // scaleSqrt scaleLinear scaleOrdinal scalePow().exponent(.5)
                                        .range([1.0,5.5])
                                        .domain( d3.extent(tl_data, scaleAccessor) ); // Note: extent caluclates the min/max of the data based on the dose key
                            
            Logger.log("size domain", this.graph.scales.size.domain());            

            this.graph.origin.x = this.vertical ? self.graph.rect.width/2 : 0; // 120
            this.graph.origin.y = this.vertical ? 0 : self.graph.rect.bottom;

            //Add a scale value to each item,
            for(let i=0; i<tl_data.length; i++) {
                tl_data[i]["scale"] = self.graph.scales.size(tl_data[i]["doses"]);
                let tx = self.graph.scales.x0(tl_data[i].date);
                let ty = self.graph.rect.height;
                tl_data[i]["x"] = this.vertical ? self.graph.origin.x : tx;
                tl_data[i]["y"] = this.vertical ? tx : ty;
            }

            if(this.vertical) {

                root_g.append("g")
                    .classed("axis x-axis graph-num", true)
                    .attr("transform", `translate(${self.graph.origin.x},${self.graph.origin.y})`) // this.graph.rect.height
                    .call( d3.axisLeft(this.graph.scales.x0).tickSize(self.graph.origin.x-20) )

                root_g.append("text") // The LHS Y axis label
                    .attr("transform", `translate(${self.graph.origin.x+35}, ${self.graph.origin.y-4})`)
                    .classed("axis_label graph-lab", true)
                    .text("VACCINATION");

                // Add second label.
                root_g.append("text") // The LHS Y axis label
                    .attr("transform", `translate(${self.graph.origin.x+35}, ${self.graph.origin.y+10})`)
                    .classed("axis_label graph-lab", true)
                    .text("QUANITITY");           
                
                // Date axis label
                root_g.append("text")
                    .attr("transform", `rotate(90) translate(${this.graph.svg_dims.height/2}, 36)`)
                    .attr("text-anchor", "middle")
                    .classed("axis_label xlab graph-lab", true)
                    .text("FULL ADULT VACCINATION DATE");


            }else{  
                // Do key LHS (Y axis)
                root_g.append("g")
                    .classed("axis x-axis graph-num", true)
                    .attr("transform", "translate(0," + this.graph.rect.height + ")")
                    .call( d3.axisBottom(this.graph.scales.x0).tickSize(110) )
                    .call(g => g.selectAll(".tick text").attr("dy", 22).attr("dx", 2))  // offset the labels

                root_g.append("text") // The LHS Y axis label
                    .attr("transform", `translate(${-7}, ${this.graph.rect.bottom-34}) rotate(-90)`)
                    .classed("axis_label graph-lab", true)
                    .text("VACCINATION");

                // Add second label.
                root_g.append("text") // The LHS Y axis label
                    .attr("transform", `translate(${10}, ${this.graph.rect.bottom-34}) rotate(-90)`)
                    .classed("axis_label graph-lab", true)
                    .text("QUANITITY");         
                    
                // Do date axis label
                root_g.append("text") 
                    .attr("transform", `translate(${this.graph.rect.width/2}, ${this.graph.svg_dims.height-10})`)
                    .attr("text-anchor", "middle")
                    .classed("axis_label graph-lab", true)
                    .text("FULL ADULT VACCINATION DATE");
            }

            // Add indicator donut at 0,0 point of graph
            root_g.append("g")
                .classed("guide-donut", true)
                .attr("transform", (d) => `translate(${self.graph.origin.x},${self.graph.origin.y})`)
                .append('path')
                    .attr('d', d3.arc()
                        .startAngle(0)
                        .endAngle((Math.PI*2))
                        .innerRadius(this.BASE_DONUT_RAD-(8))  // This is the size of the donut hole
                        .outerRadius(this.BASE_DONUT_RAD)                         
                    )
                    .attr("fill", "#66747f");
          
            // Add Country Donuts
            let donuts_g = root_g.append("g").classed("donuts", true);

            let touchEnabled = Utils.isTouchEnabled();

            if(touchEnabled) {
                svg.select(".clickRect")
                    .on("click", function(e, d) { // close any open tooltips
                        return self.hideGraphTip();
                });
            }

            donuts_g.selectAll("g.donut")
                .data(tl_data)
                .enter()
                .append("g").classed("donut", true)
                        .on("click", function(e, d) {
                            d3.select(this).raise(); // move to front
                            return self.showGraphTip(d,e,this,true);
                        })
                        .on("mouseover", function(e, d) {
                            if(touchEnabled) return;
                            //Logger.log("rt:mouseover",this);
                            d3.select(this).raise(); // move to front
                            return self.showGraphTip(d,e,this)
                        })
                        .on("mouseout", function(e, d) {
                            if(touchEnabled) return;
                           // d3.select(this).lower(); // move back
                            return self.hideGraphTip(this);
                        })
                    .attr("transform", (d) => `translate(${d.x}, ${d.y}) scale(${d.scale})`)
                    .selectAll('path')
                    .data( (d) => d.arcs )
                    .join('path')
                    .attr('fill', (d,i) => d.col )
                    .attr('opacity', (d) => 0)
                    .attr('d', d3.arc()
                        .innerRadius( 1 )
                        .outerRadius( 1.5 ) 
                    )
                    .transition().ease(d3.easeBounceOut).delay( (d,i) => 400 + d.index*100 ).duration(1200)
                    .attr('d', d3.arc()
                        .innerRadius( this.BASE_DONUT_RAD-(8) )  // This is the size of the donut hole
                        .outerRadius( this.BASE_DONUT_RAD )  
                    )
                    .attr('opacity', (d) => .9 * d.opacity)
                        
        },
        
        showGraphTip(data, event, elem, click) {
            Logger.log("RT:showGraphTip",{data, event, click},this.tooltip_cc);

            var now_ms = Utils.getElapsedMilliseconds();

            if(click === true) { //toggle on click
                if(this.tooltip_cc == data.code && (now_ms - this.last_tooltip_shown_time > 400)) {
                    this.hideGraphTip(elem);
                    return;
                }
                // d3.select(elem).attr("stroke", "red");
            }

            this.last_tooltip_shown_time = now_ms;

            this.tooltip_cc = data.code;
            this.tooltip_elem = elem;

            // Position 
            this.rollover_origin_pos.x = (this.graph.margin.left) + data.x; // event.offsetX;

            let rad = data.scale * this.BASE_DONUT_RAD;

            this.rollover_origin_pos.y = (this.graph.margin.top) + data.y - rad; // this.vertical ? event.offsetY : event.offsetY;
            
            this.sel_tl_data = data;
            this.tooltip_visible = true;
        }, 

        hideGraphTip(elem) {
            
            if(this.tooltip_visible == false) return;

            Logger.log("RT:hideGraphTip", elem);
            // d3.select("#graph_svg .tooltip").attr("style", "display:none;");
            if(elem) {
                d3.select(elem).lower(); // move back
            }else if(this.tooltip_elem) {
                d3.select(this.tooltip_elem).lower(); // move back
            }

            this.tooltip_elem = null;
            this.tooltip_visible = false;
            this.tooltip_cc = "";
        },

    }
}

</script>

<style lang="scss"  >

    @import "../vars.scss";
    
    .reg-tl {

        /* Re-define as some scoping or data bindnig type issue is causing this to reset */
        /*h1 { 
            @include h1();
        }*/
        
        .tipfade-enter-active {
            transition: opacity .2s ease-in-out 0s; /** last arg is delay */
        }
        .tipfade-leave-active {
            transition: opacity .2s ease-in-out .3s;
        }
        .tipfade-enter, .tipfade-leave-to {
            opacity: 0;
        }

        .text-col {
            margin:0 auto;
            padding-bottom:$section_copy_bottom_pad;
        }

        .tl {
            width:100%;
            height:auto;
            position:relative;
            
           .chart {
               
                width:100%;

                svg {
                    overflow:visible;
                    text {
                        fill:$mid-grey;
                    }

                    .axis {
                        line, path {
                            stroke:$dark-col;
                        }
                    }
                }

           }

            .xlab {
                /*color:$mid-grey;*/
                /*text-align: center;*/
            }

        }

    }

</style>