
<template>
    <section class="country-ov inner-col">

        <a class="anchor" :name='anchor' />

        <div class="text-col" >

            <div class="h1">COUNTRY OVERVIEW</div>
            <div class="copy" v-html="pvGetContent('country_overview')"></div>

            <transition name="fade" mode="out-in" >
                <div v-show="!is_selected" class="initial-country-selector">
                    <div class="h1">DIVE DOWN TO COUNTRY LEVEL DATA</div>
                    <Dropdown @selected="dropDownSelected" heading="SELECT A COUNTRY" :data_provider="countries" 
                    :auto_open="false" data_key="code" class="h2" arrow_y_pad=".66em"/>
                </div>
            </transition>

        </div>

        <a class="anchor" name='country-overview-graph' />

        <transition name="fade" mode="out-in" >

            <div v-if="is_selected" class="chart columns is-marginless is-desktop">

                <CountryInfoPanel class="column is-full-tablet is-4-desktop"/>

                <div class="column is-8-desktop is-full-tablet is-paddingless">
                    
                    <div class="columns is-marginless is-multiline is-mobile">
                        <VaccinationGraph ref="graph" :country_overview="true" class="column is-full is-paddingless" />
                        <div v-if="show_coming_soon_msg"  class="coming-soon column is-full" >                        
                            <div class="h4">NEW FEATURE COMING SOON</div>
                            <p class="h5">See how increasing the available workforce or acceptance rate can help bring forward a country’s full vaccination date</p>
                        </div>

                        <div v-if="show_sliders" class="column is-full">
                            <FactorAdjustor class="" />
                        </div>
                    </div>

                </div>

            </div>

        </transition>

    </section>
</template>

<script>

import CountryInfoPanel from './CountryInfoPanel';
import VaccinationGraph from './VaccinationGraph';
import Dropdown from './Dropdown';
import Logger from './js/Logger';
import Consts from './js/Consts';

import FactorAdjustor from './FactorAdjustor';

export default {
    name:'CountryOverview',
    props: {
       anchor:  {type:String, default:'country-overview'}       
    },
    data() {
        return { 
            selected: false
        }
    },
    components: {
       CountryInfoPanel, VaccinationGraph, Dropdown, FactorAdjustor
    },
    computed: {
        countries() {
            return this.$store.getters["recipient_countries"];
        },
        selected_country_index() {
            return this.$store.getters["selected_country_overview_index"];
        },
        is_selected() {
            return this.selected;
        },
        show_sliders(){
            return true;
        },
        show_coming_soon_msg() {
            return !Consts.USE_MODEL;
        }
    },
    watch: {

    },
    mounted() {
        
    },
    methods: {

        build() {
            Logger.log("build", this.anchor);
            this.$nextTick( () => {
                if(this.$refs.graph) this.$refs.graph.updateGraph(true, true);
            });
        },
        dropDownSelected(index, id) {
            Logger.log("CountryOverview::dropDownSelected", index, id);
            this.selected = (index > -1);
            this.$store.commit("selected_country_overview_index", {index,id});
        }
    }
}
</script>

<style lang="scss"  >

    @import "../vars.scss";

    .country-ov  {

        .text-col {
            margin:0 auto;
            padding-bottom:$section_copy_bottom_pad;
        }

        .initial-country-selector {
            .h1 {
                margin-top:2.0em;
                margin-bottom:1.0em;
            }
            padding-bottom:2.0em;
        }

        .chart {
            /*width:100%;*/
            /*margin-top:0.8em;*/
            .columns {
                /*border:1px dashed red;*/
            }
            .column {
               /* border:1px dashed yellow;*/
            }

        }

    }
    
</style>