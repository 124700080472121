import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

import Logger from '../components/js/Logger';
import Utils from '../components/js/Utils';
import Consts from '../components/js/Consts';

export const VUE_DEV = (process.env["NODE_ENV"] == "development");
export const DATA_FEED_URL = "";

//export const DATA_ENDPOINT_URL 			= "https://pacificvaccination-embargo.lowyinstitute.org/pac-vac-backend/public/api/graph_data";
export const DATA_ENDPOINT_URL = "https://pacificvaccination.lowyinstitute.org/pac-vac-backend/public/api/graph_data";
//export const DATA_ENDPOINT_URL 			=  "graph_data";//"_dev_live_data.json"; 
//export const DATA_ENDPOINT_URL 			=  "graph_data_latest.json";//"_dev_live_data.json"; 

import {TestData} from '../components/js/SampleModelCountryData' // DEV Data! to remove once Model feed is done.

export default new Vuex.Store({
  state: {

    loading_data: false, // status flag
    data_loaded: false, // true when data successfully loaded

    data_updated_dt: new Date(2021,8,1), // date of current "actual" data - gets set from cms

    recipient_countries: [ // This gets replaced by the data feed.
      {code:"ck", name:"Cook Islands"}, 
      {code:"fj", name:"Fiji"}, 
      {code:"ki", name:"Kiribati"},
      {code:"mh", name:"Marshall Islands"} ,
      {code:"fm", name:"Micronesia"} ,
      {code:"nr", name:"Nauru"},
      {code:"nu", name:"Niue"}, 
      {code:"pw", name:"Palau"}, 
      {code:"pg", name:"Papua New Guinea"},
      {code:"ws", name:"Samoa"}, 
      {code:"sb", name:"Solomon Islands"},
      {code:"to", name:"Tonga"},
      {code:"tv", name:"Tuvalu"}, 
      {code:"vu", name:"Vanuatu"},
    ],

    source_countries:[
        {code:"au", name:"Australia"}, 
        {code:"us", name:"United States"},
        {code:"jp", name:"Japan"},
        {code:"nz", name:"New Zealand"},
        {code:"cn", name:"China"}, 
        {code:"cv", name:"Covax"},
        {code:"ot", name:"Other"},
    ],

    content:{ // Body copy
      "home": "",
      "regional_timeline": "",
      "regional_overview": "",    
      "country_overview": "",    
      "vaccine_sources": "",    
      "methodology": "",    
      "information": "",    
      "about": ""
    },

    selected_country_overview_index: 0, // What country is being show in the overview graph / panel.
    selected_country_pop_view_complete: false, // The view is at 100 before projections - already finished

    slider_vaccincators_count: 62,
    slider_max_vaccincator_count: 120,
    slider_acceptance_rate: .67,

    is_embedded: false

  },
  mutations: { // $store.commit("selected_country_overview_index", index);
    
    loading_data(state, value) {
      Logger.log(`state loading_data to ${value}`);
      state.loading_data = value;
    },

    data_loaded(state, value) {
      state.data_loaded = value;
    },

    is_embedded(state, value) {
      state.is_embedded = value;
    },

    slider_vaccincators_count(state, value) {
      state.slider_vaccincators_count = value;
    },
    
    slider_max_vaccincator_count(state, value) {
      state.slider_max_vaccincator_count = value;
    },
   
    slider_acceptance_rate(state, value) {
      state.slider_acceptance_rate = value;
    },

    selected_country_overview_index(state, value) {
      Logger.log("Store set selected_country_overview_index", value);
      state.selected_country_overview_index = value.index;
    },

    selected_country_pop_view_complete(state, value) {
      state.selected_country_pop_view_complete = value;
    },

    initData(state, data) {
      Logger.log("initData",data);

      if(Utils.has(data, "data_update") && data["data_update"].length > 5) {
        state.data_updated_dt = Utils.mkDate(data["data_update"]);
      }

      if(Utils.has(data, "countries") && data["countries"].length) {
        let cdata = data["countries"];
        let c = cdata.length;
        Logger.log("Has Countries", c);       

        let out = [];
        
        const checkRatesAt100 = (dat) => {
          //if(!Array.isArray(dat)) return false;

          // Check if vac rates for all 3 population types and dosages are <= 100
          let a = dat[Consts.POP_18_PLUS][Consts.FIRST_VACC] >= 100;
          let b = dat[Consts.POP_18_PLUS][Consts.SECOND_VACC] >= 100;

          let c = dat[Consts.POP_12_PLUS][Consts.FIRST_VACC] >= 100;
          let d = dat[Consts.POP_12_PLUS][Consts.SECOND_VACC] >= 100;

          let e = dat[Consts.POP_TOTAL][Consts.FIRST_VACC] >= 100;
          let f = dat[Consts.POP_TOTAL][Consts.SECOND_VACC] >= 100;
          return a && b && c && d && e && f;
        }

        const checkNotZeroed = (dat) => {
          //if(!Array.isArray(dat)) return false;

          // Check if vac rates for all 3 population are not all empty.

          let a = dat[Consts.POP_18_PLUS][Consts.FIRST_VACC] > 0;
          let b = dat[Consts.POP_18_PLUS][Consts.SECOND_VACC] > 0;

          let c = dat[Consts.POP_12_PLUS][Consts.FIRST_VACC] > 0;
          let d = dat[Consts.POP_12_PLUS][Consts.SECOND_VACC] > 0;

          let e = dat[Consts.POP_TOTAL][Consts.FIRST_VACC] > 0;
          let f = dat[Consts.POP_TOTAL][Consts.SECOND_VACC] > 0;
          return a || b || c || d || e || f;
        }

        // Store data points when a country Pop reaches its maximum 2nd dose value (usually 100)
        const _calcMaxPreCalc = (pop_id, val, max_precalc) => {
          if(max_precalc[pop_id] == null) {
            max_precalc[pop_id] = {...val};
          }else{
            // Compare val's Consts.SECOND_VACC 
            let cur = max_precalc[pop_id][pop_id][Consts.SECOND_VACC];
            let nxt = val[pop_id][Consts.SECOND_VACC];
            //debugger;
            if( nxt > cur && nxt <= 100 ) { //
              max_precalc[pop_id] = {...val};
            }
          }
        }
        const calcMaxPreCalc = ( max_precalc, val  ) => {
          _calcMaxPreCalc(Consts.POP_18_PLUS, val, max_precalc);
          _calcMaxPreCalc(Consts.POP_12_PLUS, val, max_precalc);
          _calcMaxPreCalc(Consts.POP_TOTAL, val, max_precalc);          
        }

        // 
        const TRIM_ZERO_HEAD = true; // Trim so there is only one zeroed value at front


        // Walk all the countries.
        for(let i = 0; i<c; i++) {
          //Logger.log(i);
          
          cdata[i]["timeline"]["date"] = Utils.mkDate( cdata[i]["timeline"]["date"] );

          // Calculate the timelines dose based on population values.
          let adult_pop = cdata[i]["model"]["static"]["population"]["total"][Consts.POP_18_PLUS];
          
          let tl_end_pop = cdata[i]["timeline"]["pop"] / 100;
          Logger.log("Pop mod", {cc:cdata[i]["code"], tl_end_pop, adult_pop, mod_adult_pop: adult_pop*tl_end_pop});

          if(tl_end_pop < 1.0) {
            // Modifiy total doses to take into acount this country not reaching 100% in our base timeline.
            // adult_pop += (1.0-tl_end_pop) * adult_pop;
            adult_pop *= tl_end_pop;
          }
          cdata[i]["timeline"]["doses"] = adult_pop * 2;


          // Add table that stores the maximum % of SECOND_VACC reached for every country
          // Aim to store value object when the coutnry gets to 100 (or best)
          cdata[i]["max_precalc"] = {};
          /*cdata[i]["max_precalc"][Consts.POP_18_PLUS] = null;
          cdata[i]["max_precalc"][Consts.POP_12_PLUS] = null;
          cdata[i]["max_precalc"][Consts.POP_TOTAL] = null;*/

          cdata[i].completed = false; // Flag to mark data sets that are all at 100.

          if(cdata[i]["vacc_rates"].length) {
            // Turn every date string into a date
            //let cc = cdata[i]["code"];
            
            let clean_rates = []; // Truncate the rates to when they all get to 100;
            let full_rates = []; // all the rates (including pre-pre-projected)

            //cdata[i]["vacc_rates"].forEach(function (v,i,arr) {
            var last_v=null, left_zero=false;

            for(let j=0; j<cdata[i]["vacc_rates"].length; j++) {

              let v = cdata[i]["vacc_rates"][j];
              v["dt"] = Utils.mkDate( v["dt"] );

              // DEV Hack dont add the "pre-calculated" Projected data from the feed and let the model do it.
              let is_pre_projected_data = (v["dt"] > state.data_updated_dt);
              
              if(is_pre_projected_data) {
                // Logger.log("Stopping adding date data for " + cdata[i].name, v["dt"].getMonth(), state.data_updated_dt.getMonth());
                // break;
              }
              
              // var break_after_add = false;

              // Check if all doses percentages are less than 100 before adding to the data.

              let at100 = checkRatesAt100(v);

              if( at100 ) {
                if(!is_pre_projected_data) {
                  clean_rates.push(v);  // Add first 1 that is all at 100.
                  cdata[i].completed = true;
                }
                calcMaxPreCalc( cdata[i]["max_precalc"], v );
                full_rates.push(v);
                break;
              }else { // Failed. not all things are at 100
                
                let can_add = true;
                calcMaxPreCalc( cdata[i]["max_precalc"], v );

                if(TRIM_ZERO_HEAD) {                  
                  
                  can_add = false;

                  if(left_zero) {
                    can_add = true;
                  }else if( checkNotZeroed(v) && j>0 && last_v) {
                    // We have found our first one not zerod
                    // Add the previous one. (0,0,0)
                    left_zero = true;
                    if(!is_pre_projected_data) {
                      clean_rates.push(last_v);
                    }
                    full_rates.push(last_v);
                    can_add = true;
                  }
                }

                if(can_add) {
                  if(!is_pre_projected_data) {
                    clean_rates.push(v);
                  }
                  full_rates.push(v);
                }
              }

              last_v = v;

            }

            cdata[i]["vacc_rates"] = clean_rates;
            cdata[i]["full_vacc_rates"] = full_rates;

            Logger.log("MaxPreCalc:" + cdata[i].name, 
              Utils.dateToMYYYY(cdata[i]["max_precalc"][Consts.POP_18_PLUS]["dt"]),
              Utils.dateToMYYYY(cdata[i]["max_precalc"][Consts.POP_12_PLUS]["dt"]),
              Utils.dateToMYYYY(cdata[i]["max_precalc"][Consts.POP_TOTAL]["dt"]),
               cdata[i]["max_precalc"]);

            out.push(cdata[i]); // only add countries that have rates 
          }

          // DEV add missing model data to our dev file.
          if(!Utils.has(cdata[i], "model")) {
            Logger.warn("Adding test model data to " + cdata[i].name);
            cdata[i].model = {...TestData.model};
          } else{
            let c = cdata[i].model.actual.months.length;
            if(c != 3) {
              console.warn(cdata[i].name, "model.actual.months.length must be 3. It's " + c);
            }
          }

          cdata[i].model.code = cdata[i]["code"]; // insert ccode.

        }

        state.recipient_countries = out;
        // Logger.log("Post Porcessed Countries", state.recipient_countries);
      }

      if(Utils.has(data, "sources") && data["sources"].length) {
        let sdata = data["sources"];
        let c = sdata.length;
        Logger.log("Has Sources", c);
        state.source_countries = sdata;
      }

      if(Utils.has(data, "content")) {
        Logger.log("Has Content");
        let cont = data["content"];
        state.content = cont;
      }

    }

  },

  getters: {

    loading_data(state) {
      return state.loading_data;
    },
    data_loaded(state) {
      return state.data_loaded;
    },
    data_updated_dt(state) {
      return state.data_updated_dt;
    },

    is_embedded(state) {
      return state.is_embedded;
    },

    selected_country_pop_view_complete(state) {
      return state.selected_country_pop_view_complete;
    },

    slider_vaccincators_count (state) {
      return state.slider_vaccincators_count;
    },

    slider_max_vaccincator_count(state) {
      return state.slider_max_vaccincator_count;
    },
    
    slider_acceptance_rate(state) {
      return state.slider_acceptance_rate;
    },

    recipient_countries(state) {
      return state.recipient_countries;
    },
    source_countries(state) {
      return state.source_countries;
    },
    selected_country_overview_index(state) {
      return state.selected_country_overview_index;
    },

    content(state) {
      return state.content;
    },

  },
 

  actions: {

    fetchData({commit, state, rootState})  {
      
      commit("loading_data", true);

      axios.get(DATA_ENDPOINT_URL)
          .then( (d) => {
            commit("initData", d.data);
            commit("loading_data", false);   
            commit("data_loaded", true); // 
          })
          .catch( (error) => {
            Logger.error("fetchData", error);
            commit("loading_data", false);   
            // TODO: handle this in ui and retry.
      });
    }
    
  },

  modules: {

  }
})
