<!-- Vaccine Source mini table element of country -->
<template>
    <div class="source_cell columns is-vcentered is-mobile">
        <div class="circle_icon column is-narrow is-paddingless">
            <img class='icn' :style="iconScaleTransform" src="../assets/src_donut.svg" />
        </div>
        <div class="flag_icon column is-paddingless is-narrow">
            <img class='icn' :src="flag_url" />
        </div>
        <div class="info column is-narrow">
            <div class="tit h2">{{title}}</div>
            <div class="stat-table columns is-marginless is-mobile">
                <div class="column is-narrow is-paddingless ">
                    <div class="val h4">{{formated_amount}}</div>
                    <div class="lab h6">DOSES COMMITTED</div>
                </div>

                <!-- this used to make vertical line -->
                <!-- 
                <div class="divider-line is-narrow column"> 
                    <div /> 
                </div>
                <div class="column is-paddingless">
                    <div class="val h5">{{date}}</div>
                    <div class="lab h6">DATE COMMITTED</div>
                </div>
                -->
            </div>
        </div>
    </div>
</template>
<script>

import Consts from "./js/Consts";
import Utils from "./js/Utils";

export default {
    name:'SourceCell',
    props: {
        id:     {type:String, default:Consts.COVAX},
        index:  {type:Number, default:0},
        title:  {type:String, default:''},
        date:   {type:String, default:''},
        amount: {default:''},
        scale:  {type:Number, default:1.0},
    },
    data() {
        return { 
        }
    },
    computed: {
        iconScaleTransform() {
            return "transform:scale(" + this.scale + ")";
        },

        flag_url() {
            return require('../assets/flags/src/' + this.id + '.svg'); // this forces webpack to get all images in the source dir.
        },
        formated_amount() {
            return Utils.formatDosesAmount(this.amount);
        }
    },
    watch: {
    },
    mounted() {
        
    },
    methods: {
    }
}
</script>

<style lang="scss"  >

    @import "../vars.scss";
    @import "~bulma/sass/utilities/_all.sass"; // To get at the mixins for responsiveness.
    /*$debug : true;*/
    
    .source_cell {
        
        text-align: left;
        text-transform: uppercase;

        /*background-color:red;*/
        > .column {
           padding:1em 2em;
        }

        .circle_icon {
           @if $debug { border:1px dashed white; }
           margin:0 2.5em 0 0;
           img {
               transform-origin: 50% 50%;
               width:50px;
           }
        }
        .flag_icon {
           @if $debug { border:1px dashed white; }

           .icn {
               width:90px;
               @include mobile {
                   width:70px;
               }
           }
        }

        .stat-table {
            
            @if $debug { border:1px dashed white; }
            padding-top: 0em;
            margin-top:0em;
            
            .val {
                font-weight: bold;                
            }

            .lab {
            }

            .divider-line {
                padding-top:0em;
                padding-bottom:0em;
                div {
                    height:2em;
                    border-left:1px solid white;
                }
            }
        }
    }

    .columns {
        @if $debug { border:1px dashed yellow;}
    }
    .column {
        @if $debug { border:1px dashed pink;}
    }

</style>